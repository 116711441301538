import { ArrowSvg } from '../../assets/svg/ArrowSvg';
import { IQuizItem } from '../../types/quiz';
import { GenderOption } from '../parts/GenderOption';
import { QuestionImage } from '../parts/QuestionImage';
import { QuestionText } from '../parts/QuestionText';
import { useTranslation } from 'react-i18next';

type Props = {
  item: IQuizItem;
  onSubmit: (value?: string | string[], key?: string) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
};

export const AgeQuestion = ({
  item,
  onSubmit,
  lastQuestion,
  answers,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="single-question-container">
      {item?.topReviewImage && (
        <img height="26px" src={item.topReviewImage} alt="top review" />
      )}
      {item?.topTitle && (
        <span className="single-question-top-title q-top-title age-top-heading">
          {t(item.topTitle)}
        </span>
      )}
      {item?.topSubtitle && (
        <span className="single-question-top-subtitle q-top-title age-top-title">
          {t(item.topSubtitle)}
        </span>
      )}

      <div className="age-options-wrapper">
        {item.options?.map((option) => (
          <div key={option.key} className="age-option-column">
            <div className="age-option-item">
              <img
                src={option.image}
                alt={`${option.key} portrait`}
                className="age-portrait"
                onClick={() => onSubmit(option.title, option.key)}
              />
              <div
                className="age-option"
                onClick={() => onSubmit(option.key, 'age')}
              >
                <span className="age-label">{t(option.title)}</span>
                <span className="age-arrow">
                  <ArrowSvg />
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

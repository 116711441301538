import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IQuizItem } from '../../../types/quiz';
import { Gtm } from '../../../apis/gtm';
import { useLocation } from 'react-router-dom';

type SummaryQuestionProps = {
  item: IQuizItem;
  onSubmit: () => void;
  answers?: Record<string, { value: string; title: string }>;
  brandParam?: string;
};

export const SummaryQuestion: React.FC<SummaryQuestionProps> = ({ 
  item, 
  onSubmit,
  answers,
  brandParam
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const brand = brandParam || params.get('brand') || 'default';
  
  // Fire quiz_finished event when summary page is shown
  useEffect(() => {
    const email = answers?.email?.value || '';
    Gtm.trackQuizFinish(brand, email);
  }, [brand, answers]);
  
  // Get values from answers
const currentWeight = Number(answers?.weight?.value) || Number(localStorage.getItem('current_weight')) || 55;
const targetWeight = Number(answers?.target_weight?.value) || 52;
const height = Number(answers?.height?.value) || 155;
const gender = answers?.gender?.value || 'male';
const ageKey = answers?.age?.value || '30';

// Ensure valid number inputs
if (isNaN(currentWeight) || isNaN(targetWeight) || isNaN(height)) {
  console.error("Invalid input values for weight, target weight, or height.");
}

// Calculate BMI first
const heightInM = height / 100;
const currentBMI = Math.round((currentWeight / (heightInM * heightInM)) * 10) / 10;

// Calculate future date for comparison (30 days from now)
const futureDate = new Date();
futureDate.setDate(futureDate.getDate() + 30);
const formattedDate = futureDate.toLocaleDateString('de-DE', {
  day: 'numeric',
  month: 'long',
  year: 'numeric'
});

// Estimate realistic goal date based on healthy weight loss rate of 1 kg per week
const estimateRealisticGoalDate = () => {
    const weightDifference = Math.abs(currentWeight - targetWeight);
    
    // Use a fixed 1 kg per week weight loss rate for all users regardless of gender or BMI
    const weeklyLossRate = 1.0; // 1 kg per week for everyone
    
    // Simply divide total weight difference by the weekly rate to get weeks needed
    const totalWeeks = Math.ceil(weightDifference / weeklyLossRate);
    
    const goalDate = new Date();
    goalDate.setDate(goalDate.getDate() + totalWeeks * 7);
    
    return goalDate.toLocaleDateString('de-DE', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });
};

const calculateWeightPoints = (currentWeight, targetWeight) => {
  const weightDifference = currentWeight - targetWeight;
  const midpointWeight = currentWeight - Math.ceil(weightDifference / 2);

  return {
      first: `${currentWeight}kg`,   // Start weight
      second: `${midpointWeight}kg`, // Midway weight
      third: `${targetWeight}kg`     // Target weight
  };
};

const { first, second, third } = calculateWeightPoints(currentWeight, targetWeight);

const goalDate = estimateRealisticGoalDate();

// Determine BMI category
const bmiCategory = currentBMI < 18.5 ? 'Untergewicht' : 
                    currentBMI < 25 ? 'Normalgewicht' : 
                    currentBMI < 30 ? 'Übergewicht' : 'Adipositas';

const estimateBodyFat = (weight, height, ageKey, gender = "male") => {
  // Map age keys to numerical age values
  const ageMap = {
      "under_20": 18,
      "21_30": 25,
      "31_40": 35,
      "41_50": 45,
      "51_60": 55,
      "61_70": 65,
      "70_plus": 75
  };

  const age = ageMap[ageKey] || 30; // Default to 30 if key is unknown
  const heightInM = height / 100;
  const bmi = weight / (heightInM * heightInM);
  
  // More accurate base ranges based on clinical data
  const ranges = gender === 'female' 
    ? { min: 18, max: 35 }   // Female ranges
    : { min: 10, max: 28 };  // Male ranges
    
  // Non-linear BMI correlation (higher impact at higher BMIs)
  let bodyFat;
  if (bmi < 18.5) {
    bodyFat = ranges.min + (bmi - 18.5) * 1.2;
  } else if (bmi < 25) {
    bodyFat = ranges.min + (bmi - 18.5) * 1.4;
  } else if (bmi < 30) {
    bodyFat = ranges.min + 9 + (bmi - 25) * 1.6;
  } else {
    bodyFat = ranges.min + 17 + (bmi - 30) * 1.8;
  }
  
  // Age adjustment (more pronounced after 40)
  const ageAdjustment = age > 40 ? (age - 40) * 0.2 : 0;
  bodyFat += ageAdjustment;
  
  // Keep within medically realistic ranges
  bodyFat = Math.max(ranges.min, Math.min(ranges.max, bodyFat));
  
  // Narrower range for more precise estimates
  return `${Math.round(bodyFat - 1)}-${Math.round(bodyFat + 1)}%`;
};

// Correct function calls with proper parameters
const currentBodyFat = estimateBodyFat(currentWeight, height, ageKey, gender);
const targetBodyFat = estimateBodyFat(targetWeight, height, ageKey, gender);


  return (
    <div className="summary-container">
      <h2 className="summary-title">
        Unsere Berechnungen zeigen, dass Sie bis zum <span style={{color: '#00C249'}}>{goalDate}</span> ein Gewicht von {targetWeight} kg erreichen können.
      </h2>
      <div className='summary-container-inner'>
  {/* Weight Loss Projection Section */}

      {/* Weight Chart */}
      <div className="summary-chart-container">
        <div className="summary-chart-card">
          <img className="summary-chart-image" src="/images/graph.png" />
          <div className='first-bubble'>{first}</div>
          <div className='second-bubble'>{second}</div>
          <div className='third-bubble'>{third}</div>
        </div>

        {/* BMI and Body Comparison Card */}
        <div className="summary-bmi-card">
          <h3 className="summary-bmi-title">
            <b>Ihr aktueller BMI: {bmiCategory} </b><span className="bmi-measurements">({currentWeight}kg, {height}cm)</span>
          </h3>
          
          <div className="summary-comparison-container">
            <div className="summary-comparison-item">
              <img 
              src={gender === 'female' ? '/images/women_before.png' : '/images/men_before.png'}
              alt={`${gender === 'female' ? 'Female' : 'Male'} before comparison`}
              className="summary-comparison-image summary-comparison-before"
              style={{ objectPosition: 'left center' }}
            />
              <div className="summary-comparison-info">
                <p className="summary-comparison-label">Aktuell: {currentWeight}kg</p>
                <p className="summary-comparison-detail">Körperfett: {currentBodyFat}</p>
              </div>
            </div>
            
            <div className="summary-comparison-item">
              <img 
              src={gender === 'female' ? '/images/women_after.png' : '/images/men_after.png'}
              alt={`${gender === 'female' ? 'Female' : 'Male'} after comparison`}
              className="summary-comparison-image summary-comparison-after"
              style={{ objectPosition: 'right center' }}
            />
              <div className="summary-comparison-info">
                <p className="summary-comparison-label">Ziel: <span style={{color: '#00C249'}}>{targetWeight}kg</span></p>
                <p className="summary-comparison-detail">Körperfett: <span style={{color: '#00C249'}}>{targetBodyFat}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Product Recommendation */}
      <h2 className="summary-recommendation-title">
        Ihre persönliche Produktempfehlung
      </h2>
      
      <div className="summary-product-card">
        <div className="summary-product-header">
          <div className="summary-product-logo">
            <div className="lulutox-logo"><img src="https://lulutox.com/common_file/logo.png"/></div>
          </div>
          <p className="summary-product-tagline">
            Dein Sonderangebot läuft bald ab! 70% RABATT
          </p>
        </div>
        
        <img 
          src="/images/detox_packs.png" 
          alt="Lulutox diet comparison" 
          className="summary-product-image"
        />
        
        <p className="summary-product-description">
          Dieses Paket ist nur für Anwender(innen) gedacht, die wirklich ihr Leben verändern möchten.
        </p>
        
        <div className="summary-product-pricing">
          <p className="summary-product-original-price">€ 143,80</p>
          <p className="summary-product-sale-price">42,85€</p>
          <p className="summary-product-quantity">2x Packungen</p>
        </div>
        
        <div className="summary-product-cta">
          <button className="summary-product-button" onClick={onSubmit}>
            Sichern Sie sich jetzt 70% Rabatt &gt;&gt;
          </button>
          <p className="summary-product-guarantee">100% Geld-zurück-Garantie!</p>
        </div>
      </div>

      {/* Reviews Section */}
      <div className="summary-reviews-container">
        {/* Rating Overview */}
        <div className="summary-rating-card">
          <p className="summary-rating-label">Bewertungen</p>
          <p className="summary-rating-value">4.6 von 5</p>
        </div>
        
        {/* Review Cards */}
        {renderReviewCards()}
        <div className="summary-review-card">
      <div className="summary-product-cta">
          <button className="summary-product-button" onClick={onSubmit}>
          Mehr erfahren &gt;&gt;
          </button>
          <p className="summary-product-guarantee">100% Geld-zurück-Garantie!</p>
        </div>
      </div>
      </div>
      <p className="summary-disclaimer">
        Haftungsausschluss: Individuelle Ergebnisse können variieren. Die Produkte von Akusoli sind nicht dazu gedacht, eine bestimmte Krankheit oder einen bestimmten Zustand zu diagnostizieren, zu behandeln, zu heilen oder zu verhindern, sondern sollen vielmehr eine Ergänzung zu professionell durchgeführten Behandlungen darstellen. Die Gewichtsabnahme erfolgt im Rahmen einer gesunden, kalorienreduzierten Ernährung und eines Sportprogramms. Dies ist eine Werbung und nicht der eigentliche Artikel, Blogbeitrag oder Verkäuferschutzbenachrichtigung. MARKETING OFFENLEGUNG: Bei dieser Website handelt es sich um einen Marktplatz. Als solcher sollten Sie wissen, dass der Eigentümer eine finanzielle Verbindung zu den auf der Website beworbenen Produkten und Dienstleistungen hat. Der Eigentümer erhält eine Zahlung, wenn ein qualifizierter Lead vermittelt wird, aber das ist auch schon alles.
      </p>
      </div>
    </div>
  );
};

// Helper function to render review cards
const renderReviewCards = () => {
  const reviewData = [
    {
        name: 'Isabella Neumann',
        date: 'Am 11.3.2025',
        rating: 5,
        text: 'Alles TOP. Mehrmals schon bestellt. Produkt ist etwas teuer aber dafür funktioniert es bei mir.'
    },
    {
        name: 'Alina Kaiser',
        date: 'Am 11.3.2025',
        rating: 5,
        text: 'Diese Website meinte, ich könnte bis zu 6 kg oder mehr abnehmen, aber ich habe nur 3 kg verloren... ABER ich bin nicht mal sauer! Der Tee schmeckt super und ich fühle mich viel leichter und energiegeladener. Ich bin nicht mehr so müde wie sonst und habe endlich wieder Lust, mich mehr zu bewegen! :D'
    },
    {
        name: 'Mark S.',
        date: 'Am 10.3.2025',
        rating: 5,
        text: 'Ich habe Lulutox gekauft, weil ich Gewicht verlieren wollte, aber was ich bekommen habe, war viel mehr. Schon nach einer Woche fühlte ich mich deutlich energiegeladener und weniger träge. Nach einem Monat habe ich 4,5 kg verloren – und das, obwohl ich nichts an meiner Ernährung geändert habe! Ich habe jetzt mehr Motivation, mich zu bewegen, und meine Frau trinkt den Tee jetzt auch, weil sie gesehen hat, wie viel fitter und aktiver ich geworden bin. Ich bin so froh, dass ich Lulutox entdeckt habe – es hat mein Leben verbessert!'
    },
    {
        name: 'Leonie Seidel',
        date: 'Am 10.3.2025',
        rating: 4,
        text: 'Alles ok lieferung etwas länger gedauert naja. Ware OK'
    },
    {
        name: 'Hannah Becker',
        date: 'Am 10.3.2025',
        rating: 5,
        text: 'Ehrlich gesagt war ich am Anfang sehr skeptisch. Abnehmen durch Tee? Das klang zu schön, um wahr zu sein. Aber ich dachte, für den Preis kann ich es ja mal ausprobieren. Und ich bin so froh, dass ich es getan habe! Nach ein paar Tagen war ich überrascht, wie gut ich mich fühlte – weniger aufgebläht, mehr Energie und keine ständige Müdigkeit mehr. Bisher habe ich 2 kg verloren, aber das ist für mich zweitrangig. Das bessere Körpergefühl allein ist es wert, Lulutox zu trinken.'
    },
    {
        name: 'Charlotte Vogel',
        date: 'Am 9.3.2025',
        rating: 4,
        text: 'Möchte keine Bewertung abgeben'
    },
    {
        name: 'David R.',
        date: 'Am 9.3.2025',
        rating: 5,
        text: 'Schnelle Lieferung. Produkt wie immer Prima. Danke euch!'
    },
    {
        name: 'Lea',
        date: 'Am 9.3.2025',
        rating: 3,
        text: 'Ganz okay, aber keine Wunder erwarten. Der Tee schmeckt ganz gut, und ich merke, dass meine Verdauung etwas besser geworden ist. Allerdings habe ich in vier Wochen nur 1 kg abgenommen – ich hatte mir mehr erhofft. Vielleicht funktioniert es bei anderen besser, aber für mich war der Effekt eher mild'
    },
    {
        name: 'Jana Berger',
        date: 'Am 9.3.2025',
        rating: 5,
        text: 'Ich war skeptisch, aber Lulutox ist unglaublich! Nach zwei Wochen habe ich zwar nur 2 kg verloren, aber ich fühle mich viel leichter und energiegeladener. Keine ständige Müdigkeit mehr, und mein Körper fühlt sich einfach gereinigt an. Werde definitiv weitermachen!'
    },
    {
        name: 'Sophia W.',
        date: 'Am 9.3.2025',
        rating: 5,
        text: 'Ich hätte nie gedacht, dass ein Tee meinen Kaffee ersetzen kann, aber Lulutox hat es geschafft. Ich trinke ihn seit drei Wochen jeden Morgen statt Kaffee und fühle mich energiegeladener als je zuvor. Kein Sodbrennen mehr, keine Nervosität – nur pure, natürliche Energie!'
    },
    {
        name: 'Mark Sprecher',
        date: 'Am 9.3.2025',
        rating: 5,
        text: 'Ich habe immer zwei Tassen Kaffee gebraucht, um wach zu werden. Seit ich Lulutox trinke, brauche ich das nicht mehr! Der Tee gibt mir einen sanften Energieschub und hält mich den ganzen Tag über fokussiert – ohne Koffein-Crash. Außerdem habe ich weniger Heißhunger – ein echter Gamechanger 😂'
    },
    {
        name: 'David Friedl',
        date: 'Am 8.3.2025',
        rating: 5,
        text: 'Nach vier Wochen mit Lulutox habe ich 3 kg verloren und fühle mich fitter denn je. Was mich besonders überrascht hat: Ich bin viel entspannter und weniger gestresst! Ob das an den entgiftenden Inhaltsstoffen oder daran liegt, dass ich jetzt mehr Energie für Bewegung habe, weiß ich nicht – aber ich bin auf jeden Fall sehr zufrieden und trinke ihn gerne weiter.'
    },
    {
        name: 'F. Werner',
        date: 'Am 8.3.2025',
        rating: 5,
        text: 'Wow, ich bin absolut begeistert! Nach nur zwei Wochen fühle ich mich leichter und energiegeladener, und ich habe schon 2 kg verloren. Das Beste ist, dass ich nichts an meinem Alltag ändern musste – einfach jeden Morgen eine Tasse trinken und den Tag genießen!'
    },
    {
        name: 'H.M.',
        date: 'Am 8.3.2025',
        rating: 5,
        text: 'Ich dachte erst, das wäre wieder so ein Hype-Produkt, aber ich wurde eines Besseren belehrt. Nach zwei Wochen merke ich, dass ich weniger Heißhunger habe und mich insgesamt leichter und ausgeglichener fühle. Lulutox ist definitiv kein Fake!'
    },
    {
        name: 'Melina Haas',
        date: 'Am 8.3.2025',
        rating: 5,
        text: 'Ich liebe diesen Entgiftungstee! Ich trinke ihn jetzt seit etwa zwei Monaten einmal am Tag und mein Magen fühlt sich schon viel besser an. Das hat mich dazu angetrieben, mich besser zu ernähren und mehr Sport zu treiben! Diesen Sommer gefällt mir vielleicht sogar, wie ich in meinem Badeanzug aussehe. 😍'
    },
    {
        name: 'Sofia Eckstein',
        date: 'Am 7.3.2025',
        rating: 5,
        text: 'Ich war zu faul für Sport, also dachte ich, ich probiere diesen Tee aus. 😂 Überraschung: Ich habe mehr Energie, fühle mich leichter und bewege mich jetzt automatisch mehr. Gewicht verlieren geht jetzt ganz nebenbei!'
    },
    {
        name: 'Elena Baumann',
        date: 'Am 7.3.2025',
        rating: 5,
        text: 'Nach drei Wochen kann ich eine positive Bilanz ziehen: 4 Kilo weniger und deutlich mehr Energie. Das hätte ich wirklich nicht erwartet'
    },
    {
        name: 'Lisa Pohl',
        date: 'Am 7.3.2025',
        rating: 5,
        text: 'Ich habe nur 2 kg abgenommen, aber ich fühle mich so viel besser! Mein Bauch ist nicht mehr aufgebläht, ich bin entspannter und habe endlich weniger Stress. Es ist jeden Cent wert!'
    },
    {
        name: 'Markus',
        date: 'Am 7.3.2025',
        rating: 3,
        text: 'Ich trinke Lulutox jetzt seit drei Wochen. Positiv ist, dass meine Verdauung besser geworden ist und ich mich weniger aufgebläht fühle. Allerdings habe ich bisher nur 1 kg abgenommen – ich hatte mir etwas schnellere Ergebnisse erhofft. Vielleicht braucht es einfach mehr Zeit...'
    },
    {
        name: 'Maja Arnold',
        date: 'Am 7.3.2025',
        rating: 5,
        text: 'LOB an den tollen KUNDENSERVICE. Wirklich schnelle Antwort bei Problemen. Ihr habt mir viel geholfen!!!!'
    },
    {
        name: 'Ronja Vogt',
        date: 'Am 7.3.2025',
        rating: 5,
        text: 'Alles gut soweit.'
    },
    {
        name: 'Celina Otto',
        date: 'Am 6.3.2025',
        rating: 5,
        text: 'Meine Probleme mit der Bestellung wurden kulant gelöst. Danke an das Team. Würde wieder bestellen.'
    },
    {
        name: 'Marie Keller',
        date: 'Am 6.3.2025',
        rating: 5,
        text: 'Das Produkt kam super schnell an, und ich bin wirklich begeistert von den Ergebnissen! In nur zwei Monaten habe ich bereits 6 kg abgenommen, und das ohne strenge Diäten. Die Einlagen sind außerdem sehr bequem – einfach in die Schuhe legen und fertig. Ich fühle mich fitter und leichter, und meine Füße tun nicht mehr weh, selbst nach langen Spaziergängen. Klare Empfehlung!'
    },
    {
        name: 'F. L.',
        date: 'Am 7.3.2025',
        rating: 4,
        text: 'Ich trinke Lulutox seit vier Wochen und habe 4 kg abgenommen. Ich fühle mich insgesamt leichter und weniger aufgebläht, was echt super ist! Allerdings musste ich mich erst an den Geschmack gewöhnen – er ist nicht schlecht, aber etwas intensiver als erwartet. Mit etwas Zitrone oder Honig geht\'s aber gut. Insgesamt ein solides Produkt, das hält, was es verspricht!'
    }
  ];

  return (
    <>
      {reviewData.map((review, index) => (
        <div key={index} className="summary-review-card">
          <div className="summary-review-header">
            <div className="summary-review-user-info">
              <p className="summary-review-name">{review.name}</p>
              <div className="summary-review-stars">
                {Array(5).fill(0).map((_, i) => (
                  <span key={i} className={`summary-review-star ${i < review.rating ? 'filled' : ''}`}>★</span>
                ))}
              </div>
            </div>
            <div className="summary-review-meta">
              <p className="summary-review-date">{review.date}</p>
              <p className="summary-review-verified">Verifizierter Kauf</p>
            </div>
          </div>
          <div className="summary-review-divider"></div>
          <p className="summary-review-text">{review.text}</p>
        </div>
      ))}
    </>
  );
};

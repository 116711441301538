import React from 'react';
import { ArrowSvg } from '../../assets/svg/ArrowSvg';
import { IQuizItem, Option } from '../../types/quiz';
import { splitArray } from '../../utils/array';
import { OptionButton } from '../buttons/OptionButton';
import { MultipleQuestionOptionButton } from '../buttons/MultipleQuestionOptionButton';
import { CheckboxSvg } from '../../assets/svg/Checkbox';
import { CheckboxSelectedSvg } from '../../assets/svg/CheckboxSelected';
import { WeightLossSvg } from '../../assets/svg/WeightLossSvg';
import { BloatingSvg } from '../../assets/svg/ImmunitySvg';
import { EnergySvg } from '../../assets/svg/EnergySvg';
import { ImmunitySvg } from '../../assets/svg/BloatingSvg';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  item: IQuizItem;
  onSubmit: (value: string, key: string, skip?: boolean) => void;
  selected?: Record<string, boolean>;
  additionalClass?: string;
};

export const OptionButtons: React.FC<Props> = ({
  item,
  onSubmit,
  selected,
  additionalClass,
}) => {
  const { t } = useTranslation();
  const options = item?.options;
  const columns = item?.cols ? item.cols : 1;

  const Icons = {
    arrow: ArrowSvg,
    none: null,
    checkbox: CheckboxSvg,
    checkboxSelected: CheckboxSelectedSvg,
    weightLoss: WeightLossSvg,
    immunity: ImmunitySvg,
    bloating: BloatingSvg,
    energy: EnergySvg,
  };

  const btnClass = (isSelected?: boolean) => {
    return `option-button ${isSelected ? 'active' : ''} q-option-button`;
  };

  const onClick =
    (key: string, value: string, skip?: boolean) =>
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onSubmit(key, value, skip);
    };

  const renderButton = (option: Option) => {
    if (item.type === 'single') {
      if (item.selectType === 'areasToImprove') {
        return (
          <OptionButton
            key={option.key}
            onClick={onClick(option.key, option.title)}
            className={
              btnClass(selected?.[option.key]) + ' option-areas-to-improve'
            }
          >
            <div className="option-content-areas-to-improve">
              <div className="option-left-areas-to-improve">
                <div className="option-title-row">
                  <span>
                    <Trans
                      i18nKey={
                        option.titleHtml ? option.titleHtml : option.title
                      }
                      components={
                        option.titleHtml
                          ? {
                              1: <b style={{ fontWeight: 'bold' }} />,
                              b: <b style={{ fontWeight: 'bold' }}></b>,
                            }
                          : {}
                      }
                    />
                  </span>
                </div>
                {option.description && (
                  <div className="option-description-areas-to-improve">
                    <span>{t(option.description)}</span>
                  </div>
                )}
              </div>
              <div className="option-right-areas-to-improve">
                {option.image && (
                  <img
                    src={option.image}
                    className="option-icon"
                    style={{ width: '140px' }}
                  />
                )}
              </div>
            </div>
          </OptionButton>
        );
      } else {
        const OptionIcon = item?.optionIcon
          ? Icons[item?.optionIcon as keyof typeof Icons]
          : null;
        return (
          <OptionButton
            key={option.key}
            onClick={onClick(option.key, option.title)}
            className={btnClass(selected?.[option.key])}
          >
            {t(option.title)}
            {OptionIcon && <OptionIcon />}
          </OptionButton>
        );
      }
    }

    if (item.type === 'multiple') {
      if (item.selectType === 'areasToImprove') {
        return (
          <OptionButton
            key={option.key}
            onClick={onClick(option.key, option.title)}
            className={
              btnClass(selected?.[option.key]) + ' option-areas-to-improve'
            }
          >
            <div className="option-content-areas-to-improve">
              <div className="option-left-areas-to-improve">
                <div className="option-title-row">
                  {selected?.[option.key] ? (
                    <CheckboxSelectedSvg />
                  ) : (
                    <CheckboxSvg />
                  )}
                  <span style={option.titleHtml ? { fontWeight: 'bold' } : {}}>
                    <Trans
                      i18nKey={option.titleHtml || option.title}
                      components={
                        option.titleHtml ? { 1: <b />, b: <b /> } : {}
                      }
                    />
                  </span>
                </div>
                {option.description && (
                  <div className="option-description-areas-to-improve">
                    <span>{t(option.description)}</span>
                  </div>
                )}
              </div>
              <div className="option-right-areas-to-improve">
                {option.image && (
                  <img
                    src={option.image}
                    className="option-icon"
                    style={{ width: '140px' }}
                  />
                )}
              </div>
            </div>
          </OptionButton>
        );
      }
      if (option.skip) {
        return (
          <OptionButton
            key={option.key}
            onClick={onClick(option.key, option.title, option.skip)}
            className={btnClass(selected?.[option.key])}
          >
            <span>
              <Trans i18nKey={option.title} />
            </span>
            {<ArrowSvg />}
          </OptionButton>
        );
      } else {
        const OptionIcon = option.icon
          ? Icons[option.icon as keyof typeof Icons]
          : null;
        return (
          <OptionButton
            key={option.key}
            onClick={onClick(option.key, option.title)}
            className={btnClass(selected?.[option.key])}
          >
            <div className="option-content">
              <div className="option-left">
                {OptionIcon && <OptionIcon className="option-icon" />}
                <span>
                  <Trans
                    i18nKey={option.titleHtml || option.title}
                    components={option.titleHtml ? { 1: <b />, b: <b /> } : {}}
                  />
                </span>
              </div>
              <div className="option-right">
                {selected?.[option.key] ? (
                  <CheckboxSelectedSvg />
                ) : (
                  <CheckboxSvg />
                )}
              </div>
            </div>
          </OptionButton>
        );
      }
    }
  };

  if (!options || options.length === 0) {
    return null;
  }
  if (columns === 1) {
    return (
      <div
        className={`option-buttons-container ${additionalClass}`}
        id="option-buttons"
      >
        {options.map((option) => renderButton(option as unknown as Option))}
      </div>
    );
  }
  const [col1, col2] = splitArray(options);
  return (
    <div className="option-buttons-grid" id="option-buttons">
      <div className="option-buttons-column">
        {col1.map((option) => renderButton(option as unknown as Option))}
      </div>
      <div className="option-buttons-column">
        {col2.map((option) => renderButton(option as unknown as Option))}
      </div>
    </div>
  );
};

import { IQuizItem } from '../../types/quiz';
import { Button } from '../buttons/Button';
import { QuestionImage } from '../parts/QuestionImage';
import { QuestionLottie } from '../parts/QuestionLottie';
import { QuestionText } from '../parts/QuestionText';
import { useTranslation } from 'react-i18next';

type Props = {
  item: IQuizItem;
  onSubmit: (value: string | any) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
};

export const InfoQuestion: React.FC<Props> = ({
  item,
  onSubmit,
  lastQuestion,
  answers,
}) => {
  const { t } = useTranslation();
  return (
    <div className="info-question-container">
      {item.lottie && <QuestionLottie item={item} />}
      {item.image && <QuestionImage item={item} info={true} />}
      <QuestionText item={item} answers={answers} />
      {item.imageBottom && <QuestionImage item={item} info={true} />}
      <Button className="q-cta-button mt-4" onClick={onSubmit}>
        {t(item.cta) || 'Continue'}
      </Button>
    </div>
  );
};

import { IQuizItem } from '../../types/quiz';
import { OptionButtons } from '../parts/OptionButtons';
import { QuestionImage } from '../parts/QuestionImage';
import { QuestionLottie } from '../parts/QuestionLottie';
import { QuestionText } from '../parts/QuestionText';
import { useTranslation } from 'react-i18next';

type Props = {
  item: IQuizItem;
  onSubmit: (value: string) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
};

export const SingleQuestion = ({
  item,
  onSubmit,
  lastQuestion,
  answers,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="single-question-container">
      {item?.topReviewImage && (
        <img height="26px" src={item.topReviewImage} alt="top review" />
      )}
      {item?.topTitle && (
        <span className="single-question-top-title q-top-title">
          {t(item.topTitle)}
        </span>
      )}
      {item?.topSubtitle && (
        <span className="single-question-top-subtitle q-top-title">
          {t(item.topSubtitle)}
        </span>
      )}
      <QuestionImage item={item} />
      <QuestionText item={item} answers={answers} />
      {item.lottie && <QuestionLottie item={item} />}
      <OptionButtons item={item} onSubmit={onSubmit} />
    </div>
  );
};

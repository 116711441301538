import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Quiz } from './components/Quiz';
import { QuizLulutoxTest } from './components/QuizLulutoxTest';
import { QuizLulutoxTestGender } from './components/QuizLulutoxTestGender';
import { QuizLulutoxTestUIX } from './components/QuizLulutoxTestUIX';
import { WellarayTikTok } from './components/WellarayTikTok';
import { QuizLulutoxInterstitial } from './components/QuizLulutoxInterstitial';

interface AppProps {
  locale: string;
}

const App: React.FC<AppProps> = ({ locale }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale, i18n]);

  return (
    <Routes>
      <Route path="/" Component={Quiz} />
      {/* quiz.lulutox.com Routes */}
      <Route path="/v1" Component={QuizLulutoxTest} />
      <Route
        path="/tt"
        element={
          <QuizLulutoxTestGender
            questionsId="lu-tt"
            nextUrl="http://getlulutox.com/products/product-tt"
          />
        }
      />
      <Route
        path="/vbf"
        element={
          <QuizLulutoxTest
            nextUrl="https://shop.lulutox.com/products/product-1-s"
            appendAnswersToUrl={true}
            questionsId="lu-test-summary"
          />
        }
      />
      <Route
        path="/vbf-2"
        element={
          <QuizLulutoxTestUIX
            nextUrl="https://shop.lulutox.com/products/product-1-s"
            appendAnswersToUrl={true}
            questionsId="lu-test-summary-2"
          />
        }
      />
      <Route
        path="/vbf-sub"
        element={
          <QuizLulutoxTestUIX
            nextUrl="https://shop.lulutox.com/products/product-sub-s"
            appendAnswersToUrl={true}
            questionsId="lu-test-summary-2"
          />
        }
      />
      <Route
        path="/vbf-old"
        element={
          <QuizLulutoxTest nextUrl="https://lulutox.com/products/product-qzbf75" />
        }
      />

      {/* quiz.wellaray.com Routes */}
      <Route
        path="/es/vbf-w-tt"
        element={
          <WellarayTikTok
            nextUrl="https://wellaray.com/products/product-1us-es"
            questionsId="wellaray-tt"
          />
        }
      />
      <Route
        path="/vbf-w"
        element={
          <QuizLulutoxTest
            nextUrl="https://wellaray.com/products/product-1/"
            questionsId="wellaray-main"
          />
        }
      />
      <Route
        path="/vbf-w-tt"
        element={
          <WellarayTikTok
            nextUrl="https://wellaray.com/products/product-tt"
            questionsId="wellaray-tt"
          />
        }
      />
      <Route
        path="/vbf-w-sub"
        element={
          <QuizLulutoxTest
            nextUrl="https://wellaray.com/products/product-sub/"
            questionsId="wellaray-main"
          />
        }
      />

      {/* quiz.getellaray.com Routes */}
      <Route
        path="/vbf-gw-sub"
        element={
          <QuizLulutoxTest
            nextUrl="https://getwellaray.com/products/product-sub/"
            questionsId="wellaray-main"
          />
        }
      />
      
      {/* German Lulutox Interstitial Route */}
      <Route
        path="/de/lulutox-interstitial"
        element={
          <QuizLulutoxInterstitial
            nextUrl="https://cart.lulutox.com/products-order/de/"
            questionsId="lu-interstitial-gw"
          />
        }
      />
    </Routes>
  );
};

export default App;

import { IQuizItem } from '../types/quiz';

export interface Quiz {
  id: string;
  headerStyle?: string;
  footerStyle?: string;
  progressBarStyle?: string;
  questions: IQuizItem[];
  loaderType?: 'review' | 'default';
}

export const quizzes: Quiz[] = [
  {
    id: 'lu-main',
    questions: [
      {
        key: 'goal',
        type: 'single',
        topTitle: 'Lighter, Energized, Glowing - One Cup At a Time',
        topSubtitle:
          'Lulutox tea consists of 12 well-known, delicious ingredients that can help you achieve various health goals.',
        topReviewImage: '/images/reviews-lulutox.png',
        title: 'My goal is:',
        options: [
          {
            title: 'Manage weight',
            key: 'manage_weight',
          },
          {
            title: 'Detox my body',
            key: 'detox_body',
          },
          {
            title: 'Relieve menopause symptoms',
            key: 'menopause_symptoms',
          },
          {
            title: 'Relieve bloating/constipation',
            key: 'bloating_constipation',
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'symptoms',
        type: 'single',
        title: 'Do you experience any of the following symptoms?',
        options: [
          {
            title: 'Unwanted weight',
            key: 'unwanted_weight',
          },
          {
            title: 'Bloating',
            key: 'bloating',
          },
          {
            title: 'Sleep difficulties',
            key: 'sleep_difficulties',
          },
          {
            title: 'Low energy',
            key: 'low_energy',
          },
          {
            title: 'None',
            key: 'none',
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'gained_weight_last_year',
        type: 'single',
        title: 'Have you gained weight in the last year?',
        options: [
          {
            title: 'Yes',
            key: 'yes',
          },
          {
            title: "I'm not sure",
            key: 'not_sure',
          },
          {
            title: 'No',
            key: 'no',
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'change_notice_time',
        type: 'single',
        title:
          'When do you expect to see your first results after using Lulutox?',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Withing a week',
            key: 'within_a_week',
          },
          {
            title: 'Within a month',
            key: 'within_a_month',
          },
          {
            title: 'Within 2-3 months',
            key: 'within_two_three_months',
          },
          {
            title: "I don't have any expectations",
            key: 'no_expectations',
          },
        ],
      },
      {
        key: 'health_improvement_offer',
        type: 'info',
        title: 'We can help you improve your health!',
        subtitleHtml:
          '<b>Most users</b> noticed positive effects from Lulutox within a week.',
        optionIcon: 'arrow',
        image:
          'https://cdn.prod.website-files.com/660d1017ba78506b9fd36e6f/66e7f28cca69d1bf7d4e6343_Quiz%20Br.png',
      },
      {
        key: 'age_group',
        type: 'single',
        title:
          'To help us further personalize your offer, please tell us which age group you are in.',
        optionIcon: 'arrow',
        options: [
          {
            title: '18-24',
            key: '18-24',
          },
          {
            title: '25-34',
            key: '25-34',
          },
          {
            title: '35-44',
            key: '35-44',
          },
          {
            title: '45-54',
            key: '45-54',
          },
          {
            title: '55-64',
            key: '55-64',
          },
          {
            title: '65+',
            key: '65+',
          },
        ],
      },
      {
        key: 'weight_reduction_stat',
        type: 'chart',
        image: '/images/lulutox-testimonial-desktop.png',
        imageMobile: '/images/lulutox-testimonial-mobile.png',
        titleHtml:
          '<span style="color:#e4469d;">94%</span> of users experienced weight reduction within the first month.',
        cta: 'Get my results',
      },
      {
        key: 'email',
        type: 'email',
        label: 'Enter your email',
        title:
          'Enter your email to receive your quiz results and discover the ideal solution for improving your health:',
        cta: 'Get my results',
      },
    ],
  },
  {
    id: 'we-main',
    questions: [
      {
        key: 'gender',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Male',
            key: 'male',
          },
          {
            title: 'Female',
            key: 'female',
          },
          {
            title: 'Other',
            key: 'other',
          },
        ],
        title: 'To begin choose your gender:',
        topTitle:
          'Answer a couple of brief questions to share your experiences, and we’ll uncover how knee patches might bring comfort to your daily routine.',
        type: 'single',
      },
      {
        key: 'knee_pain',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Yes',
            key: 'yes',
          },
          {
            title: 'No',
            key: 'no',
          },
        ],
        title: 'Do you experience any type of knee pain (or pain in general):',
        type: 'single',
      },
      {
        key: 'pain_often',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Daily',
            key: 'daily',
          },
          {
            title: 'Weekly',
            key: 'weekly',
          },
          {
            title: 'Monthly',
            key: 'monthly',
          },
          {
            title: 'Rarely',
            key: 'rarely',
          },
        ],
        title: 'How often do you feel this pain?',
        type: 'single',
      },
      {
        key: 'how_long',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Less than 3 months',
            key: 'less_than_3_months',
          },
          {
            title: '3-6 months',
            key: '3_6_months',
          },
          {
            title: '6-12 months',
            key: '6_12_months',
          },
          {
            title: 'More than 1 year',
            key: 'more_than_1_year',
          },
        ],
        title: 'How long have you been dealing with joint or muscle pain?',
        type: 'single',
      },
      {
        key: 'chart_1',
        type: 'chart',
        image: '/images/chart-wellnee.png',
        imageMobile: '/images/chart-wellnee.png',
        titleHtml:
          '<span style="color:#00C249;">82%</span> of our customers report faster results with Wellnee',
        cta: 'Continue',
      },
      {
        key: 'products_tried',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Over-the-counter pain medications',
            key: 'over_the_counter_pain_medications',
          },
          {
            title: 'Physical therapy or exercises',
            key: 'physical_therapy_or_exercises',
          },
          {
            title: 'Other',
            key: 'other',
          },
          {
            title: 'Have not tried any pain relief methods',
            key: 'have_not_tried_any_pain_relief_methods',
          },
          {
            title: 'Topical pain relief creams or gels',
            key: 'topical_pain_relief_creams_or_gels',
          },
          {
            title: 'Knee braces or supports',
            key: 'knee_braces_or_supports',
          },
        ],
        title:
          'Have you previously tried any of the following knee pain relief products or treatments?',
        type: 'single',
      },
      {
        key: 'looking_for_solution',
        type: 'single',
        title:
          'Are you looking for a long-term solution or immediate relief for your pain?',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Long term - I want to relieve knee pain for good',
            key: 'long_term_solution',
          },
          {
            title: 'Short term - I want to end knee pain as soon as possible',
            key: 'immediate_relief',
          },
        ],
      },
      {
        key: 'age',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Up to 30',
            key: 'up_to_30',
          },
          {
            title: '30 - 45',
            key: '30_45',
          },
          {
            title: '46 - 60',
            key: '46_60',
          },
          {
            title: '61+',
            key: '61+',
          },
        ],
        title: 'What is your age?',
        type: 'single',
      },
      {
        key: 'email',
        type: 'email',
        label: 'Enter your email',
        title:
          'Enter your email to receive your quiz results and discover the ideal pain relief solution:',
        cta: 'Get my results',
      },
    ],
  },
  {
    id: 'lu-test',
    headerStyle: 'bannerHeader',
    footerStyle: 'logosFooter',
    progressBarStyle: 'bannerHeader',
    questions: [
      {
        key: 'goal',
        type: 'multiple',
        title: "Select the areas you'd like to improve",
        cta: 'Next',
        step: 0,
        options: [
          {
            icon: 'weightLoss',
            titleHtml: '<b>Weight:</b> Loss & management',
            title: 'Weight: Loss & management',
            key: 'weight_loss_management',
          },
          {
            icon: 'immunity',
            titleHtml: '<b>Immunity:</b> Boost & improvement',
            title: 'Immunity: Boost & improvement',
            key: 'immunity_boost_improvement',
          },
          {
            icon: 'bloating',
            titleHtml: '<b>Bloating:</b> Reduction & management',
            title: 'Bloating: Reduction & management',
            key: 'bloating_reduction_management',
          },
          {
            icon: 'energy',
            titleHtml: '<b>Energy:</b> Increase & stability',
            title: 'Energy: Increase & stability',
            key: 'energy_increase_stability',
          },
        ],
        optionIcon: 'checkbox',
        optionSelectedIcon: 'checkbox-selected',
      },
      {
        key: 'suffer_from',
        type: 'multiple',
        title: 'Do you suffer from any of the following:',
        cta: 'Next',
        step: 0,
        options: [
          {
            title: 'Trouble losing weight',
            key: 'trouble_losing_weight',
          },
          {
            title: 'Bloating or digestion issues',
            key: 'bloating',
          },
          {
            title: 'Low energy levels',
            key: 'low_energy_levels',
          },
          {
            title: 'Feeling under the weather',
            key: 'feeling_under_the_weather',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'gained_weight_last_year',
        type: 'single',
        title: 'Have you experienced unwanted weight gain in the past year?',
        step: 0,
        options: [
          {
            title: 'Yes, a lot',
            key: 'yes_a_lot',
          },
          {
            title: 'Yes, a little bit',
            key: 'yes_a_little_bit',
          },
          {
            title: "I'm not sure",
            key: 'not_sure',
          },
          {
            title: "No, I haven't",
            key: 'no_i_havent',
          },
          {
            title: "No, I've actually lost weight",
            key: 'no_i_ve_lost_weight',
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'based_on_answers_1',
        step: 1,
        type: 'info',
        title: 'Based on your answers, we can help!',
        subtitleHtml:
          '<b>9 out of 10 users</b> saw a reduction in weight and improvement in wellbeing within the first month.',
        optionIcon: 'arrow',
        lottie: {
          path: '/animations/lottie/heart.json',
          width: 200,
          height: 200,
        },
        cta: 'Next',
      },
      {
        key: 'bloating_bothers',
        step: 1,
        type: 'multiple',
        title: 'What bothers you the most about bloating?',
        optionIcon: 'arrow',
        cta: 'Next',
        options: [
          {
            title: 'Painful pressure',
            key: 'painful_pressure',
          },
          {
            title: 'Self-conscious due to appearance',
            key: 'self_conscious_due_to_appearance',
          },
          {
            title: 'Accumulating gas',
            key: 'accumulating_gas',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
      },
      {
        key: 'age_group',
        type: 'single',
        step: 1,
        title: 'How can we tailor our recommendations to suit your age group?',
        optionIcon: 'arrow',
        options: [
          {
            title: '18-24',
            key: '18-24',
          },
          {
            title: '25-34',
            key: '25-34',
          },
          {
            title: '35-44',
            key: '35-44',
          },
          {
            title: '45-54',
            key: '45-54',
          },
          {
            title: '55+',
            key: '55+',
          },
        ],
      },
      {
        key: 'based_on_answers_2',
        type: 'info',
        step: 2,
        title: 'Amazing news!',
        subtitleHtml:
          'Women aged <b>{age_group}</b> with similar concerns had a <b>93% success rate</b> with Lulutox!',
        optionIcon: 'arrow',
        lottie: {
          path: '/animations/lottie/heart.json',
          width: 200,
          height: 200,
        },
        cta: 'Next',
      },
      {
        key: 'energy_levels',
        step: 2,
        type: 'multiple',
        title: 'Are you concerned about your energy levels?',
        optionIcon: 'arrow',
        cta: 'Next',
        options: [
          {
            title: "Yes, I'm constantly tired",
            key: 'yes_im_constantly_tired',
          },
          {
            title: 'Sometimes I get post-lunch slump',
            key: 'sometimes_i_get_post_lunch_slump',
          },
          {
            title: 'No, I have loads of energy',
            key: 'no_i_have_loads_of_energy',
          },
          {
            title: "I don't know",
            key: 'dont_know',
            skip: true,
          },
        ],
      },
      {
        key: 'immune_system',
        type: 'single',
        step: 2,
        title: 'Would you say you have a strong immune system?',
        optionIcon: 'arrow',
        options: [
          {
            title: "No, I'm constantly sick",
            key: 'no_im_constantly_sick',
          },
          {
            title: 'No, not really',
            key: 'no_not_really',
          },
          {
            title: 'Not sure, but concerned about it',
            key: 'not_sure_but_concerned',
          },
          {
            title: 'Yes, I feel great',
            key: 'yes_i_feel_great',
          },
          {
            title: "Yes, but I'm interested in boosting my immunity",
            key: 'yes_but_interested_in_boosting_immunity',
          },
        ],
      },
      {
        key: 'tea_blend',
        type: 'single',
        step: 3,
        title: 'Have you ever tried a detoxifying, energizing tea blend?',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Yes, in the past',
            key: 'yes_in_the_past',
          },
          {
            title: 'Yes, I have it now',
            key: 'yes_i_have_it_now',
          },
          {
            title: 'No',
            key: 'no',
          },
        ],
      },
      {
        key: 'digestive_issues',
        type: 'multiple',
        step: 3,
        title: 'Do you experience any of these digestive issues?',
        optionIcon: 'arrow',
        cta: 'Next',
        options: [
          {
            title: 'Indigestion',
            key: 'indigestion',
          },
          {
            title: 'Constipation',
            key: 'constipation',
          },
          {
            title: 'Irregularity',
            key: 'irregularity',
          },
          {
            title: 'Food intolerances',
            key: 'food_intolerances',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
      },
      {
        key: 'loader',
        type: 'loader',
        step: 3,
        titleHtml:
          "<b style='color:#E4469D;'>93%</b> of users experienced weight reduction within the first month.",
        image: '/images/lulutox-loader-before-after.png',
        loaderTexts: ['Analyzing your answers...'],
      },
    ],
  },
  {
    id: 'lu-test-summary',
    headerStyle: 'bannerHeader',
    footerStyle: 'logosFooter',
    progressBarStyle: 'bannerHeader',
    questions: [
      {
        key: 'goal',
        type: 'multiple',
        title: "Select the areas you'd like to improve",
        cta: 'Next',
        step: 0,
        options: [
          {
            icon: 'weightLoss',
            titleHtml: '<b>Weight:</b> Loss & management',
            title: 'Weight: Loss & management',
            key: 'weight_loss_management',
          },
          {
            icon: 'immunity',
            titleHtml: '<b>Immunity:</b> Boost & improvement',
            title: 'Immunity: Boost & improvement',
            key: 'immunity_boost_improvement',
          },
          {
            icon: 'bloating',
            titleHtml: '<b>Bloating:</b> Reduction & management',
            title: 'Bloating: Reduction & management',
            key: 'bloating_reduction_management',
          },
          {
            icon: 'energy',
            titleHtml: '<b>Energy:</b> Increase & stability',
            title: 'Energy: Increase & stability',
            key: 'energy_increase_stability',
          },
        ],
        optionIcon: 'checkbox',
        optionSelectedIcon: 'checkbox-selected',
      },
      {
        key: 'suffer_from',
        type: 'multiple',
        title: 'Do you suffer from any of the following:',
        cta: 'Next',
        step: 0,
        options: [
          {
            title: 'Trouble losing weight',
            key: 'trouble_losing_weight',
          },
          {
            title: 'Bloating or digestion issues',
            key: 'bloating',
          },
          {
            title: 'Low energy levels',
            key: 'low_energy_levels',
          },
          {
            title: 'Feeling under the weather',
            key: 'feeling_under_the_weather',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'gained_weight_last_year',
        type: 'single',
        title: 'Have you experienced unwanted weight gain in the past year?',
        step: 0,
        options: [
          {
            title: 'Yes, a lot',
            key: 'yes_a_lot',
          },
          {
            title: 'Yes, a little bit',
            key: 'yes_a_little_bit',
          },
          {
            title: "I'm not sure",
            key: 'not_sure',
          },
          {
            title: "No, I haven't",
            key: 'no_i_havent',
          },
          {
            title: "No, I've actually lost weight",
            key: 'no_i_ve_lost_weight',
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'based_on_answers_1',
        step: 1,
        type: 'info',
        title: 'Based on your answers, we can help!',
        subtitleHtml:
          '<b>9 out of 10 users</b> saw a reduction in weight and improvement in wellbeing within the first month.',
        optionIcon: 'arrow',
        lottie: {
          path: '/animations/lottie/heart.json',
          width: 200,
          height: 200,
        },
        cta: 'Next',
      },
      {
        key: 'bloating_bothers',
        step: 1,
        type: 'multiple',
        title: 'What bothers you the most about bloating?',
        optionIcon: 'arrow',
        cta: 'Next',
        options: [
          {
            title: 'Painful pressure',
            key: 'painful_pressure',
          },
          {
            title: 'Self-conscious due to appearance',
            key: 'self_conscious_due_to_appearance',
          },
          {
            title: 'Accumulating gas',
            key: 'accumulating_gas',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
      },
      {
        key: 'age_group',
        type: 'single',
        step: 1,
        title: 'How can we tailor our recommendations to suit your age group?',
        optionIcon: 'arrow',
        options: [
          {
            title: '18-24',
            key: '18-24',
          },
          {
            title: '25-34',
            key: '25-34',
          },
          {
            title: '35-44',
            key: '35-44',
          },
          {
            title: '45-54',
            key: '45-54',
          },
          {
            title: '55+',
            key: '55+',
          },
        ],
      },
      {
        key: 'based_on_answers_2',
        type: 'info',
        step: 2,
        title: 'Amazing news!',
        subtitleHtml:
          'Women aged <b>{age_group}</b> with similar concerns had a <b>93% success rate</b> with Lulutox!',
        optionIcon: 'arrow',
        lottie: {
          path: '/animations/lottie/heart.json',
          width: 200,
          height: 200,
        },
        cta: 'Next',
      },
      {
        key: 'energy_levels',
        step: 2,
        type: 'single',
        title: 'Are you concerned about your energy levels?',
        optionIcon: 'arrow',
        cta: 'Next',
        options: [
          {
            title: "Yes, I'm constantly tired",
            key: 'yes_im_constantly_tired',
          },
          {
            title: 'Sometimes I get post-lunch slump',
            key: 'sometimes_i_get_post_lunch_slump',
          },
          {
            title: 'No, I have loads of energy',
            key: 'no_i_have_loads_of_energy',
          },
          {
            title: "I don't know",
            key: 'dont_know',
          },
        ],
      },
      {
        key: 'immune_system',
        type: 'single',
        step: 2,
        title: 'Would you say you have a strong immune system?',
        optionIcon: 'arrow',
        options: [
          {
            title: "No, I'm constantly sick",
            key: 'no_im_constantly_sick',
          },
          {
            title: 'No, not really',
            key: 'no_not_really',
          },
          {
            title: 'Not sure, but concerned about it',
            key: 'not_sure_but_concerned',
          },
          {
            title: 'Yes, I feel great',
            key: 'yes_i_feel_great',
          },
          {
            title: "Yes, but I'm interested in boosting my immunity",
            key: 'yes_but_interested_in_boosting_immunity',
          },
        ],
      },
      {
        key: 'tea_blend',
        type: 'single',
        step: 3,
        title: 'Have you ever tried a detoxifying, energizing tea blend?',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Yes, in the past',
            key: 'yes_in_the_past',
          },
          {
            title: 'Yes, I have it now',
            key: 'yes_i_have_it_now',
          },
          {
            title: 'No',
            key: 'no',
          },
        ],
      },
      {
        key: 'digestive_issues',
        type: 'multiple',
        step: 3,
        title: 'Do you experience any of these digestive issues?',
        optionIcon: 'arrow',
        cta: 'Next',
        options: [
          {
            title: 'Indigestion',
            key: 'indigestion',
          },
          {
            title: 'Constipation',
            key: 'constipation',
          },
          {
            title: 'Irregularity',
            key: 'irregularity',
          },
          {
            title: 'Food intolerances',
            key: 'food_intolerances',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
      },
      {
        key: 'loader',
        type: 'loader',
        step: 3,
        titleHtml:
          "<b style='color:#E4469D;'>93%</b> of users experienced weight reduction within the first month.",
        image: '/images/lulutox-loader-before-after.png',
        loaderTexts: ['Analyzing your answers...'],
      },
    ],
  },
  {
    id: 'lu-test-summary-2',
    headerStyle: 'default',
    footerStyle: 'logosFooter',
    progressBarStyle: 'default',
    questions: [
      {
        key: 'age_group',
        type: 'age',
        topTitle: 'Shed Weight the Lazy Way',
        topSubtitle: 'According to your age and BMI',
        step: 0,
        options: [
          {
            title: 'Age: 18-29',
            key: '18-29',
            image: '/images/age-18-29.png',
          },
          {
            title: 'Age: 30-39',
            key: '30-39',
            image: '/images/age-30-39.png',
          },
          {
            title: 'Age: 40-49',
            key: '40-49',
            image: '/images/age-40-49.png',
          },
          {
            title: 'Age: 50+',
            key: '50+',
            image: '/images/age-50+.png',
          },
        ],
      },
      {
        key: 'goal',
        type: 'single',
        selectType: 'areasToImprove',
        title: "Select the areas you'd like to improve",
        cta: 'Next',
        step: 0,
        options: [
          {
            icon: 'weightLoss',
            titleHtml: 'Weight:',
            description: 'Loss & management',
            title: 'Weight: Loss & management',
            key: 'weight_loss_management',
            image: '/images/areas-weight.png',
          },
          {
            icon: 'immunity',
            titleHtml: 'Immunity:',
            description: 'Boost & improvement',
            title: 'Immunity: Boost & improvement',
            key: 'immunity_boost_improvement',
            image: '/images/immunity.png',
          },
          {
            icon: 'bloating',
            titleHtml: 'Bloating:',
            description: 'Reduction & management',
            title: 'Bloating: Reduction & management',
            key: 'bloating_reduction_management',
            image: '/images/bloating.png',
          },
          {
            icon: 'energy',
            titleHtml: 'Energy:',
            description: 'Increase & stability',
            title: 'Energy: Increase & stability',
            key: 'energy_increase_stability',
            image: '/images/energy.png',
          },
        ],
        optionIcon: 'checkbox',
        optionSelectedIcon: 'checkbox-selected',
      },
      {
        key: 'gained_weight_last_year',
        type: 'single',
        title: 'Have you experienced unwanted weight gain in the past year?',
        step: 0,
        lottie: {
          path: '/animations/lottie/scales.json',
          width: 120,
          height: 120,
          className: 'lottie-scales',
        },
        options: [
          {
            title: 'Yes, a lot',
            key: 'yes_a_lot',
          },
          {
            title: 'Yes, a little bit',
            key: 'yes_a_little_bit',
          },
          {
            title: "I'm not sure",
            key: 'not_sure',
          },
          {
            title: "No, I haven't",
            key: 'no_i_havent',
          },
          {
            title: "No, I've actually lost weight",
            key: 'no_i_ve_lost_weight',
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'based_on_answers_1',
        step: 1,
        type: 'info',
        title: 'Based on your answers, we can help!',
        subtitleHtml:
          '<b>9 out of 10 users</b> saw a reduction in weight and improvement in wellbeing within the first month.',
        optionIcon: 'arrow',
        image: '/images/based_on_answers_test.png',
        cta: 'Next',
      },
      {
        key: 'bloating_bothers',
        step: 1,
        type: 'multiple',
        title: 'What bothers you the most about bloating?',
        optionIcon: 'arrow',
        cta: 'Next',
        lottie: {
          path: '/animations/lottie/balloon.json',
          width: 120,
          height: 120,
          className: 'lottie-balloon',
        },
        options: [
          {
            title: 'Painful pressure',
            key: 'painful_pressure',
          },
          {
            title: 'Self-conscious due to appearance',
            key: 'self_conscious_due_to_appearance',
          },
          {
            title: 'Accumulating gas',
            key: 'accumulating_gas',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
      },

      {
        key: 'based_on_answers_2',
        type: 'info',
        step: 2,
        title: 'Amazing news!',
        subtitleHtml:
          'Women aged <b>{age_group}</b> with similar concerns had a <b>93% success rate</b> with Lulutox!',
        optionIcon: 'arrow',
        imageBottom: '/images/before-after-test-lulutox.png',
        imageWidth: '400px',
        cta: 'Next',
      },
      {
        key: 'energy_levels',
        step: 2,
        type: 'single',
        title: 'Are you concerned about your energy levels?',
        optionIcon: 'arrow',
        cta: 'Next',
        lottie: {
          path: '/animations/lottie/tired.json',
          width: 120,
          height: 120,
          className: 'lottie-tired',
        },
        options: [
          {
            title: "Yes, I'm constantly tired",
            key: 'yes_im_constantly_tired',
          },
          {
            title: 'Sometimes I get post-lunch slump',
            key: 'sometimes_i_get_post_lunch_slump',
          },
          {
            title: 'No, I have loads of energy',
            key: 'no_i_have_loads_of_energy',
          },
          {
            title: "I don't know",
            key: 'dont_know',
          },
        ],
      },
      {
        key: 'immune_system',
        type: 'single',
        step: 2,
        title: 'Would you say you have a strong immune system?',
        optionIcon: 'arrow',
        lottie: {
          path: '/animations/lottie/immune.json',
          width: 100,
          height: 120,
          className: 'lottie-immune',
        },
        options: [
          {
            title: "No, I'm constantly sick",
            key: 'no_im_constantly_sick',
          },
          {
            title: 'No, not really',
            key: 'no_not_really',
          },
          {
            title: 'Not sure, but concerned about it',
            key: 'not_sure_but_concerned',
          },
          {
            title: 'Yes, I feel great',
            key: 'yes_i_feel_great',
          },
          {
            title: "Yes, but I'm interested in boosting my immunity",
            key: 'yes_but_interested_in_boosting_immunity',
          },
        ],
      },
      {
        key: 'tea_blend',
        type: 'single',
        step: 3,
        title: 'Have you ever tried a detoxifying, energizing tea blend?',
        optionIcon: 'arrow',
        lottie: {
          path: '/animations/lottie/tea.json',
          width: 80,
          height: 100,
          className: 'lottie-tea',
        },
        options: [
          {
            title: 'Yes, in the past',
            key: 'yes_in_the_past',
          },
          {
            title: 'Yes, I have it now',
            key: 'yes_i_have_it_now',
          },
          {
            title: 'No',
            key: 'no',
          },
        ],
      },
      {
        key: 'digestive_issues',
        type: 'multiple',
        step: 3,
        title: 'Do you experience any of these digestive issues?',
        optionIcon: 'arrow',
        cta: 'Next',
        lottie: {
          path: '/animations/lottie/stomach.json',
          width: 100,
          height: 120,
          className: 'lottie-stomach',
        },
        options: [
          {
            title: 'Indigestion',
            key: 'indigestion',
          },
          {
            title: 'Constipation',
            key: 'constipation',
          },
          {
            title: 'Irregularity',
            key: 'irregularity',
          },
          {
            title: 'Food intolerances',
            key: 'food_intolerances',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
      },
      {
        key: 'loader',
        type: 'loader',
        step: 3,
        titleHtml:
          "<b style='color:#E4469D;'>93%</b> of users experienced weight reduction within the first month.",
        image: '/images/loader-lulutox-test-1.png',
        images: [
          '/images/loader-lulutox-test-1.png',
          '/images/loader-lulutox-test-2.png',
          '/images/loader-lulutox-test-3.png',
        ],
        imageWidth: '430px',
        loaderTexts: ['Analyzing your answers...'],
      },
    ],
  },
  {
    id: 'lu-tt',
    headerStyle: 'bannerHeader',
    footerStyle: 'logosFooter',
    progressBarStyle: 'bannerHeader',
    questions: [
      {
        key: 'gender',
        type: 'gender',
        topTitle: 'Achieve Your Wellness Goals with Ease',
        topSubtitle:
          'Supports your body’s natural detox process and promotes a balanced, healthy lifestyle – helping you feel your best every day!',
        step: 0,
        options: [
          {
            title: 'Male',
            key: 'male',
          },
          {
            title: 'Female',
            key: 'female',
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'goal',
        type: 'multiple',
        title: "Select the areas you'd like to improve",
        cta: 'Next',
        step: 0,
        options: [
          {
            icon: 'weightLoss',
            titleHtml: '<b>Health:</b> Support Healthy Body Balance',
            title: 'Weight: Loss & management',
            key: 'weight_loss_management',
          },
          {
            icon: 'immunity',
            titleHtml: '<b>Immunity:</b> Boost & improvement',
            title: 'Immunity: Boost & improvement',
            key: 'immunity_boost_improvement',
          },
          {
            icon: 'bloating',
            titleHtml: '<b>Bloating:</b> Reduction & management',
            title: 'Bloating: Reduction & management',
            key: 'bloating_reduction_management',
          },
          {
            icon: 'energy',
            titleHtml: '<b>Energy:</b> Increase & stability',
            title: 'Energy: Increase & stability',
            key: 'energy_increase_stability',
          },
        ],
        optionIcon: 'checkbox',
        optionSelectedIcon: 'checkbox-selected',
      },
      {
        key: 'suffer_from',
        type: 'multiple',
        title: 'Do you suffer from any of the following:',
        cta: 'Next',
        step: 0,
        options: [
          {
            title: 'Trouble losing weight',
            key: 'trouble_losing_weight',
          },
          {
            title: 'Bloating or digestion issues',
            key: 'bloating',
          },
          {
            title: 'Low energy levels',
            key: 'low_energy_levels',
          },
          {
            title: 'Feeling under the weather',
            key: 'feeling_under_the_weather',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'gained_weight_last_year',
        type: 'single',
        title: 'Have you experienced unwanted weight gain in the past year?',
        step: 0,
        options: [
          {
            title: 'Yes, a lot',
            key: 'yes_a_lot',
          },
          {
            title: 'Yes, a little bit',
            key: 'yes_a_little_bit',
          },
          {
            title: "I'm not sure",
            key: 'not_sure',
          },
          {
            title: "No, I haven't",
            key: 'no_i_havent',
          },
          {
            title: "No, I've actually lost weight",
            key: 'no_i_ve_lost_weight',
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'based_on_answers_1',
        step: 1,
        type: 'info',
        title: 'Based on your answers, we can help!',
        subtitleHtml:
          '<b>9 out of 10 users</b> saw a reduction in weight and improvement in wellbeing within the first month.',
        optionIcon: 'arrow',
        lottie: {
          path: '/animations/lottie/heart.json',
          width: 200,
          height: 200,
        },
        cta: 'Next',
      },
      {
        key: 'bloating_bothers',
        step: 1,
        type: 'multiple',
        title: 'What bothers you the most about bloating?',
        optionIcon: 'arrow',
        cta: 'Next',
        options: [
          {
            title: 'Painful pressure',
            key: 'painful_pressure',
          },
          {
            title: 'Self-conscious due to appearance',
            key: 'self_conscious_due_to_appearance',
          },
          {
            title: 'Accumulating gas',
            key: 'accumulating_gas',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
      },
      {
        key: 'age_group',
        type: 'single',
        step: 1,
        title: 'How can we tailor our recommendations to suit your age group?',
        optionIcon: 'arrow',
        options: [
          {
            title: '18-24',
            key: '18-24',
          },
          {
            title: '25-34',
            key: '25-34',
          },
          {
            title: '35-44',
            key: '35-44',
          },
          {
            title: '45-54',
            key: '45-54',
          },
          {
            title: '55+',
            key: '55+',
          },
        ],
      },
      {
        key: 'based_on_answers_2',
        type: 'info',
        step: 2,
        title: 'Amazing news!',
        subtitleHtml:
          '{gender} aged <b>{age_group}</b> with similar concerns had a <b>93% success rate</b> with Lulutox!',
        optionIcon: 'arrow',
        lottie: {
          path: '/animations/lottie/heart.json',
          width: 200,
          height: 200,
        },
        cta: 'Next',
      },
      {
        key: 'energy_levels',
        step: 2,
        type: 'single',
        title: 'Are you concerned about your energy levels?',
        optionIcon: 'arrow',
        cta: 'Next',
        options: [
          {
            title: "Yes, I'm constantly tired",
            key: 'yes_im_constantly_tired',
          },
          {
            title: 'Sometimes I get post-lunch slump',
            key: 'sometimes_i_get_post_lunch_slump',
          },
          {
            title: 'No, I have loads of energy',
            key: 'no_i_have_loads_of_energy',
          },
          {
            title: "I don't know",
            key: 'dont_know',
          },
        ],
      },
      {
        key: 'immune_system',
        type: 'single',
        step: 2,
        title: 'Would you say you have a strong immune system?',
        optionIcon: 'arrow',
        options: [
          {
            title: "No, I'm constantly sick",
            key: 'no_im_constantly_sick',
          },
          {
            title: 'No, not really',
            key: 'no_not_really',
          },
          {
            title: 'Not sure, but concerned about it',
            key: 'not_sure_but_concerned',
          },
          {
            title: 'Yes, I feel great',
            key: 'yes_i_feel_great',
          },
          {
            title: "Yes, but I'm interested in boosting my immunity",
            key: 'yes_but_interested_in_boosting_immunity',
          },
        ],
      },
      {
        key: 'tea_blend',
        type: 'single',
        step: 3,
        title: 'Have you ever tried a detoxifying, energizing tea blend?',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Yes, in the past',
            key: 'yes_in_the_past',
          },
          {
            title: 'Yes, I have it now',
            key: 'yes_i_have_it_now',
          },
          {
            title: 'No',
            key: 'no',
          },
        ],
      },
      {
        key: 'digestive_issues',
        type: 'multiple',
        step: 3,
        title: 'Do you experience any of these digestive issues?',
        optionIcon: 'arrow',
        cta: 'Next',
        options: [
          {
            title: 'Indigestion',
            key: 'indigestion',
          },
          {
            title: 'Constipation',
            key: 'constipation',
          },
          {
            title: 'Irregularity',
            key: 'irregularity',
          },
          {
            title: 'Food intolerances',
            key: 'food_intolerances',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
      },
      {
        key: 'loader',
        type: 'loader',
        step: 3,
        titleHtml:
          "<b style='color:#E4469D;'>93%</b> of users experienced weight reduction within the first month.",
        image: '/images/lulutox-loader-before-after.png',
        loaderTexts: ['Analyzing your answers...'],
      },
    ],
  },
  {
    id: 'wellaray-main',
    headerStyle: 'bannerHeader',
    footerStyle: 'logosFooter',
    progressBarStyle: 'bannerHeader',
    questions: [
      {
        key: 'goal',
        type: 'multiple',
        title: "Select the areas you'd like to improve",
        cta: 'Next',
        step: 0,
        options: [
          {
            icon: 'weightLoss',
            titleHtml: '<b>Weight:</b> Loss & management',
            title: 'Weight: Loss & management',
            key: 'weight_loss_management',
          },
          {
            icon: 'immunity',
            titleHtml: '<b>Immunity:</b> Boost & improvement',
            title: 'Immunity: Boost & improvement',
            key: 'immunity_boost_improvement',
          },
          {
            icon: 'bloating',
            titleHtml: '<b>Bloating:</b> Reduction & management',
            title: 'Bloating: Reduction & management',
            key: 'bloating_reduction_management',
          },
          {
            icon: 'energy',
            titleHtml: '<b>Energy:</b> Increase & stability',
            title: 'Energy: Increase & stability',
            key: 'energy_increase_stability',
          },
        ],
        optionIcon: 'checkbox',
        optionSelectedIcon: 'checkbox-selected',
      },
      {
        key: 'suffer_from',
        type: 'multiple',
        title: 'Do you suffer from any of the following:',
        cta: 'Next',
        step: 0,
        options: [
          {
            title: 'Trouble losing weight',
            key: 'trouble_losing_weight',
          },
          {
            title: 'Bloating or digestion issues',
            key: 'bloating',
          },
          {
            title: 'Joint pain',
            key: 'joint_pain',
          },
          {
            title: 'Hot flashes',
            key: 'hot_flashes',
          },
          {
            title: 'Low energy levels',
            key: 'low_energy_levels',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'gained_weight_last_year',
        type: 'single',
        title: 'Have you experienced unwanted weight gain in the past year?',
        step: 0,
        options: [
          {
            title: 'Yes, a lot',
            key: 'yes_a_lot',
          },
          {
            title: 'Yes, a little bit',
            key: 'yes_a_little_bit',
          },
          {
            title: "I'm not sure",
            key: 'not_sure',
          },
          {
            title: "No, I haven't",
            key: 'no_i_havent',
          },
          {
            title: "No, I've actually lost weight",
            key: 'no_i_ve_lost_weight',
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'based_on_answers_1',
        step: 1,
        type: 'info',
        title: 'Based on your answers, we can help!',
        subtitleHtml:
          '<b>9 out of 10</b> users saw a reduction in weight and noticed milder menopause symptoms within the first month.',
        optionIcon: 'arrow',
        lottie: {
          path: '/animations/lottie/heart.json',
          width: 200,
          height: 200,
        },
        cta: 'Next',
      },
      {
        key: 'bloating_bothers',
        step: 1,
        type: 'multiple',
        title: 'What bothers you the most about bloating?',
        optionIcon: 'arrow',
        cta: 'Next',
        options: [
          {
            title: 'Painful pressure',
            key: 'painful_pressure',
          },
          {
            title: 'Self-conscious due to appearance',
            key: 'self_conscious_due_to_appearance',
          },
          {
            title: 'Accumulating gas',
            key: 'accumulating_gas',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
      },
      {
        key: 'age_group',
        type: 'single',
        step: 1,
        title: 'How can we tailor our recommendations to suit your age group?',
        optionIcon: 'arrow',
        options: [
          {
            title: '18-24',
            key: '18-24',
          },
          {
            title: '25-34',
            key: '25-34',
          },
          {
            title: '35-44',
            key: '35-44',
          },
          {
            title: '45-54',
            key: '45-54',
          },
          {
            title: '55+',
            key: '55+',
          },
        ],
      },
      {
        key: 'based_on_answers_2',
        type: 'info',
        step: 2,
        title: 'Amazing news!',
        subtitleHtml:
          'Women aged <b>{age_group}</b> with similar concerns had a <b>93% success rate</b> with Wellaray!',
        optionIcon: 'arrow',
        lottie: {
          path: '/animations/lottie/heart.json',
          width: 200,
          height: 200,
        },
        cta: 'Next',
      },
      {
        key: 'energy_levels',
        step: 2,
        type: 'multiple',
        title: 'Are you concerned about your energy levels?',
        optionIcon: 'arrow',
        cta: 'Next',
        options: [
          {
            title: "Yes, I'm constantly tired",
            key: 'yes_im_constantly_tired',
          },
          {
            title: 'Sometimes I get post-lunch slump',
            key: 'sometimes_i_get_post_lunch_slump',
          },
          {
            title: 'No, I have loads of energy',
            key: 'no_i_have_loads_of_energy',
          },
          {
            title: "I don't know",
            key: 'dont_know',
            skip: true,
          },
        ],
      },
      {
        key: 'hot_flashes',
        type: 'single',
        step: 2,
        title: 'Do you experience hot flashes?',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Yes, often',
            key: 'yes_often',
          },
          {
            title: 'Yes, sometimes',
            key: 'yes_sometimes',
          },
          {
            title: "I'm not sure",
            key: 'not_sure',
          },
          {
            title: "No, I don't",
            key: 'no_i_dont',
          },
        ],
      },
      {
        key: 'joint_pain',
        type: 'single',
        step: 2,
        title: 'Do you experience joint pain?',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Yes',
            key: 'yes',
          },
          {
            title: 'Sometimes',
            key: 'sometimes',
          },
          {
            title: 'No',
            key: 'no',
          },
          {
            title: "I'm not sure",
            key: 'not_sure',
          },
        ],
      },
      {
        key: 'probiotic_blend',
        type: 'single',
        step: 3,
        title:
          'Have you ever tried a probiotic blend designed to improve your well-being?',
        optionIcon: 'arrow',
        options: [
          {
            title: 'Yes, in the past',
            key: 'yes_in_the_past',
          },
          {
            title: 'Yes, I have it now',
            key: 'yes_i_have_it_now',
          },
          {
            title: 'No',
            key: 'no',
          },
        ],
      },
      {
        key: 'digestive_issues',
        type: 'multiple',
        step: 3,
        title: 'Do you experience any of these digestive issues?',
        optionIcon: 'arrow',
        cta: 'Next',
        options: [
          {
            title: 'Indigestion',
            key: 'indigestion',
          },
          {
            title: 'Constipation',
            key: 'constipation',
          },
          {
            title: 'Irregularity',
            key: 'irregularity',
          },
          {
            title: 'Food intolerances',
            key: 'food_intolerances',
          },
          {
            title: 'None',
            key: 'none',
            skip: true,
          },
        ],
      },
      {
        key: 'loader',
        type: 'loader',
        step: 3,
        titleHtml:
          "<b style='color:#F05365;'>93%</b> of users experienced weight reduction within the first month.",
        image: '/images/wellaray-before-after.png',
        loaderTexts: ['Analyzing your answers...'],
      },
    ],
  },
  {
    id: 'wellaray-tt',
    headerStyle: 'bannerHeader',
    footerStyle: 'logosFooter',
    progressBarStyle: 'bannerHeader',
    questions: [
      {
        key: 'age_group',
        type: 'age',
        title: 'quiz.wellaray_tt.question_1.top_title',
        topTitle: 'quiz.wellaray_tt.question_1.top_title',
        topSubtitle: 'quiz.wellaray_tt.question_1.top_subtitle',
        step: 0,
        options: [
          {
            title: 'quiz.wellaray_tt.question_1.options.0.title',
            key: '18-29',
            image: '/images/age-18-29.png',
          },
          {
            title: 'quiz.wellaray_tt.question_1.options.1.title',
            key: '30-39',
            image: '/images/age-30-39.png',
          },
          {
            title: 'quiz.wellaray_tt.question_1.options.2.title',
            key: '40-49',
            image: '/images/age-40-49.png',
          },
          {
            title: 'quiz.wellaray_tt.question_1.options.3.title',
            key: '50+',
            image: '/images/age-50+.png',
          },
        ],
      },
      {
        key: 'goal',
        type: 'multiple',
        title: 'quiz.wellaray_tt.question_2.title',
        cta: 'common.next',
        step: 0,
        options: [
          {
            icon: 'weightLoss',
            titleHtml: 'quiz.wellaray_tt.question_2.options.0.title_html',
            title: 'quiz.wellaray_tt.question_2.options.0.title',
            key: 'health_support_your_healthy_eating_journey',
          },
          {
            icon: 'immunity',
            titleHtml: 'quiz.wellaray_tt.question_2.options.1.title_html',
            title: 'quiz.wellaray_tt.question_2.options.1.title',
            key: 'immunity_boost_improvement',
          },
          {
            icon: 'bloating',
            titleHtml: 'quiz.wellaray_tt.question_2.options.2.title_html',
            title: 'quiz.wellaray_tt.question_2.options.2.title',
            key: 'bloating_reduction_management',
          },
          {
            icon: 'energy',
            titleHtml: 'quiz.wellaray_tt.question_2.options.3.title_html',
            title: 'quiz.wellaray_tt.question_2.options.3.title',
            key: 'energy_increase_stability',
          },
        ],
        optionIcon: 'checkbox',
        optionSelectedIcon: 'checkbox-selected',
      },
      {
        key: 'suffer_from',
        type: 'multiple',
        title: 'quiz.wellaray_tt.question_3.title',
        cta: 'common.next',
        step: 0,
        options: [
          {
            title: 'quiz.wellaray_tt.question_3.options.0.title',
            key: 'unhealthy_looking_body',
          },
          {
            title: 'quiz.wellaray_tt.question_3.options.1.title',
            key: 'bloating',
          },
          {
            title: 'quiz.wellaray_tt.question_3.options.2.title',
            key: 'joint_pain',
          },
          {
            title: 'quiz.wellaray_tt.question_3.options.3.title',
            key: 'hot_flashes',
          },
          {
            title: 'quiz.wellaray_tt.question_3.options.4.title',
            key: 'low_energy_levels',
          },
          {
            title: 'quiz.wellaray_tt.question_3.options.5.title',
            key: 'none',
            skip: true,
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'gained_weight_last_year',
        type: 'single',
        title: 'quiz.wellaray_tt.question_4.title',
        step: 0,
        options: [
          {
            title: 'quiz.wellaray_tt.question_4.options.0.title',
            key: 'yes_a_lot',
          },
          {
            title: 'quiz.wellaray_tt.question_4.options.1.title',
            key: 'yes_a_little_bit',
          },
          {
            title: 'quiz.wellaray_tt.question_4.options.2.title',
            key: 'not_sure',
          },
          {
            title: 'quiz.wellaray_tt.question_4.options.3.title',
            key: 'no_i_havent',
          },
          {
            title: 'quiz.wellaray_tt.question_4.options.4.title',
            key: 'no_i_ve_lost_weight',
          },
        ],
        optionIcon: 'arrow',
      },
      {
        key: 'based_on_answers_1',
        step: 1,
        type: 'info',
        title: 'quiz.wellaray_tt.question_5.title',
        subtitleHtml: 'quiz.wellaray_tt.question_5.subtitleHtml',
        optionIcon: 'arrow',
        lottie: {
          path: '/animations/lottie/heart.json',
          width: 200,
          height: 200,
        },
        cta: 'common.next',
      },
      {
        key: 'bloating_bothers',
        step: 1,
        type: 'multiple',
        title: 'quiz.wellaray_tt.question_6.title',
        optionIcon: 'arrow',
        cta: 'common.next',
        options: [
          {
            title: 'quiz.wellaray_tt.question_6.options.0.title',
            key: 'painful_pressure',
          },
          {
            title: 'quiz.wellaray_tt.question_6.options.1.title',
            key: 'self_conscious_due_to_appearance',
          },
          {
            title: 'quiz.wellaray_tt.question_6.options.2.title',
            key: 'accumulating_gas',
          },
          {
            title: 'quiz.wellaray_tt.question_6.options.3.title',
            key: 'none',
            skip: true,
          },
        ],
      },
      {
        key: 'based_on_answers_2',
        type: 'info',
        step: 2,
        title: 'quiz.wellaray_tt.question_7.title',
        subtitleHtml: 'quiz.wellaray_tt.question_7.subtitleHtml',
        optionIcon: 'arrow',
        lottie: {
          path: '/animations/lottie/heart.json',
          width: 200,
          height: 200,
        },
        cta: 'common.next',
      },
      {
        key: 'energy_levels',
        step: 2,
        type: 'multiple',
        title: 'quiz.wellaray_tt.question_8.title',
        optionIcon: 'arrow',
        cta: 'common.next',
        options: [
          {
            title: 'quiz.wellaray_tt.question_8.options.0.title',
            key: 'yes_im_constantly_tired',
          },
          {
            title: 'quiz.wellaray_tt.question_8.options.1.title',
            key: 'sometimes_i_get_post_lunch_slump',
          },
          {
            title: 'quiz.wellaray_tt.question_8.options.2.title',
            key: 'no_i_have_loads_of_energy',
          },
          {
            title: 'quiz.wellaray_tt.question_8.options.3.title',
            key: 'dont_know',
            skip: true,
          },
        ],
      },
      {
        key: 'hot_flashes',
        type: 'single',
        step: 2,
        title: 'quiz.wellaray_tt.question_9.title',
        optionIcon: 'arrow',
        options: [
          {
            title: 'quiz.wellaray_tt.question_9.options.0.title',
            key: 'yes_often',
          },
          {
            title: 'quiz.wellaray_tt.question_9.options.1.title',
            key: 'yes_sometimes',
          },
          {
            title: 'quiz.wellaray_tt.question_9.options.2.title',
            key: 'not_sure',
          },
          {
            title: 'quiz.wellaray_tt.question_9.options.3.title',
            key: 'no_i_dont',
          },
        ],
      },
      {
        key: 'joint_pain',
        type: 'single',
        step: 2,
        title: 'quiz.wellaray_tt.question_10.title',
        optionIcon: 'arrow',
        options: [
          {
            title: 'quiz.wellaray_tt.question_10.options.0.title',
            key: 'yes',
          },
          {
            title: 'quiz.wellaray_tt.question_10.options.1.title',
            key: 'sometimes',
          },
          {
            title: 'quiz.wellaray_tt.question_10.options.2.title',
            key: 'no',
          },
          {
            title: 'quiz.wellaray_tt.question_10.options.3.title',
            key: 'not_sure',
          },
        ],
      },
      {
        key: 'probiotic_blend',
        type: 'single',
        step: 3,
        title: 'quiz.wellaray_tt.question_11.title',
        optionIcon: 'arrow',
        options: [
          {
            title: 'quiz.wellaray_tt.question_11.options.0.title',
            key: 'yes_in_the_past',
          },
          {
            title: 'quiz.wellaray_tt.question_11.options.1.title',
            key: 'yes_i_have_it_now',
          },
          {
            title: 'quiz.wellaray_tt.question_11.options.2.title',
            key: 'no',
          },
        ],
      },
      {
        key: 'digestive_issues',
        type: 'multiple',
        step: 3,
        title: 'quiz.wellaray_tt.question_12.title',
        optionIcon: 'arrow',
        cta: 'common.next',
        options: [
          {
            title: 'quiz.wellaray_tt.question_12.options.0.title',
            key: 'indigestion',
          },
          {
            title: 'quiz.wellaray_tt.question_12.options.1.title',
            key: 'constipation',
          },
          {
            title: 'quiz.wellaray_tt.question_12.options.2.title',
            key: 'irregularity',
          },
          {
            title: 'quiz.wellaray_tt.question_12.options.3.title',
            key: 'food_intolerances',
          },
          {
            title: 'quiz.wellaray_tt.question_12.options.4.title',
            key: 'none',
            skip: true,
          },
        ],
      },
      {
        key: 'loader',
        type: 'loader',
        step: 3,
        titleHtml: 'quiz.wellaray_tt.question_13.title_html',
        image: '/images/wellaray-loader-tt.png',
        image_es: '/images/review_es.png',
        loaderTexts: ['quiz.wellaray_tt.question_13.loading_text.0.text'],
        imageWidth: '400px',
      },
    ],
  },
  {
    id: 'lu-interstitial-gw',
    headerStyle: 'default',
    footerStyle: 'logosFooter',
    progressBarStyle: 'default',
    questions: [
      {
        key: 'weight_goal',
        type: 'single',
        title: 'Was ist Ihr Hauptziel?',
        options: [
          { key: 'goal_1_5', title: 'Endgültig 1-5 kg abnehmen' },
          { key: 'goal_6_10', title: 'Endgültig 6-10 kg abnehmen' },
          { key: 'goal_11_15', title: 'Endgültig 11-15 kg abnehmen' },
          { key: 'goal_maintain', title: 'Das Gewicht halten und in Form kommen' }
        ]
      },
      {
        key: 'gender',
        type: 'gender',
        title: 'Was ist Ihr Geschlecht?',
        subtitle: 'Geschlecht und Hormone haben einen Einfluss auf den Stoffwechsel.',
        options: [
          { key: 'male', title: 'Männlich' },
          { key: 'female', title: 'Weiblich' }
        ]
      },
      {
        key: 'age',
        type: 'single',
        title: 'Wie alt sind Sie?',
        options: [
          { key: 'under_20', title: 'Unter 20 Jahre' },
          { key: '21_30', title: '21-30 Jahre' },
          { key: '31_40', title: '31-40 Jahre' },
          { key: '41_50', title: '41-50 Jahre' },
          { key: '51_60', title: '51-60 Jahre' },
          { key: '61_70', title: '61-70 Jahre' },
          { key: '70_plus', title: '70+ Jahre' }
        ]
      },
      {
        key: 'testimonial',
        type: 'interstitial-info',
        title: 'Über 20.000 zufriedene Kunden sprechen für sich!',
        subtitleHtml: '<b>Mit Lulutox setzen Sie auf eine bewährte Methode, um nachhaltig Gewicht zu verlieren und den Körper zu entgiften – ganz ohne strenge Diäten oder anstrengende Workouts.</b> Kein Wunder, dass Lulutox 2024 bereits als einer der effektivsten Detox- und Abnehmtees gefeiert wurde!',
        image: '/images/lulutox-interstitial-testimonial.png',
        cta: 'Weiter >'
      },
      {
        key: 'height',
        type: 'interstitial-input',
        title: 'Wie groß sind Sie?',
        subtitle: 'Bitte geben Sie Ihre Körpergröße ein',
        inputConfig: {
          inputType: 'number',
          fieldType: 'height',
          validation: {
            min: 150,
            max: 250,
            required: true
          }
        },
        cta: 'Weiter >'
      },
      {
        key: 'weight',
        type: 'interstitial-input',
        title: 'Wie hoch ist Ihr Gewicht?',
        subtitle: 'Bitte geben Sie Ihr derzeitiges Gewicht ein',
        inputConfig: {
          inputType: 'number',
          fieldType: 'weight',
          validation: {
            min: 50,
            max: 200,
            required: true
          }
        },
        cta: 'Weiter >'
      },
      {
        key: 'target_weight',
        type: 'interstitial-input',
        title: 'Was ist Ihr Zielgewicht?',
        subtitle: 'Bitte geben Sie Ihr Zielgewicht ein',
        inputConfig: {
          inputType: 'number',
          fieldType: 'target_weight',
          validation: {
            min: 50,
            max: 200,
            required: true,
            compareWithPrevious: true
          }
        },
        cta: 'Weiter >'
      },
      {
        key: 'diet_comparison',
        type: 'interstitial-study-info',
        title: 'Dauerhafte Ergebnisse erzielt man mit Lulutox – nicht mit restriktiven Diäten.',
        subtitleHtml: 'Eine Studie der Columbia University zeigt: <b>73 % der Menschen, die eine Diät machen, nehmen das verlorene Gewicht wieder zu – oft sogar mit zusätzlichen Kilos.</b>\n\nDagegen zeigten Studien der Universität Toronto, die Lulutox untersucht haben, dass <b>7 von 10 Teilnehmern durchschnittlich beeindruckende 6 kg pro Monat abnahmen. Zudem berichteten 87 % von einer verbesserten Verdauung und weniger Blähungen, während 94 % mehr Energie und ein leichteres Körpergefühl hatten.</b>',
        image: '/images/graph_image.png',
        cta: 'Weiter >'
      },
      {
        key: 'energy_level',
        type: 'single',
        title: 'Wie würden Sie Ihr Energielevel im Alltag beschreiben?',
        options: [
          { key: 'high', title: 'Meistens fit und energiegeladen' },
          { key: 'medium', title: 'Mal so, mal so – tagesabhängig' },
          { key: 'low', title: 'Oft müde, brauche mehr Energie' }
        ],
        optionIcon: 'arrow',
        cta: 'Weiter >'
      },
      {
        key: 'physical_activity',
        type: 'single',
        title: 'Wie regelmäßig sind Sie sportlich aktiv?',
        options: [
          { key: 'daily', title: 'Täglich oder fast täglich' },
          { key: 'weekly', title: 'Gelegentlich – ein- bis zweimal pro Woche' },
          { key: 'rarely', title: 'Selten oder nie' }
        ],
        optionIcon: 'arrow',
        cta: 'Weiter >'
      },
      {
        key: 'weight_loss_challenge',
        type: 'single',
        title: 'Was ist Ihre größte Herausforderung beim Abnehmen?',
        options: [
          { key: 'cravings', title: 'Heißhunger & ungesunde Snacks' },
          { key: 'no_time', title: 'Keine Zeit für Sport & gesunde Ernährung' },
          { key: 'slow_metabolism', title: 'Langsamer Stoffwechsel' }
        ],
        optionIcon: 'arrow',
        cta: 'Weiter >'
      },
      {
        key: 'results_loader',
        type: 'loader',
        titleHtml: 'Wir erstellen Ihren personalisierten Gewichtsabnahmeplan...',
        image: '/images/lulutox-loader-before-after.png',
        loaderTexts: ['Antworten werden analysiert...'],
        duration: 3000, // 3 seconds duration
        lottie: {
          path: '/animations/lottie/loader-interstitial.json',
          width: 128,
          height: 128,
          className: 'lottie-interstitial-loader'
        },
        cta: 'Weiter >'
      },
      {
        key: 'summary',
        type: 'summary',
        title: 'Ihr persönlicher Gewichtsabnahmeplan ist bereit!',
        subtitle: 'Basierend auf Ihren Antworten haben wir einen maßgeschneiderten Plan erstellt, der Ihnen helfen wird, Ihre Ziele zu erreichen.',
        cta: 'Jetzt bestellen'
      }
    ]
  }
];

export function getQuizById(id: string): Quiz | undefined {
  return quizzes.find((quiz) => quiz.id === id);
}

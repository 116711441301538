import React from 'react';
import './i18n';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import './index.css';
import App from './App';

const DetectLocaleWrapper = () => {
  const location = useLocation();
  const validLanguages = ['en', 'es', 'de'];

  // Extract the first part of the URL path
  const pathSegments = location.pathname.split('/');
  const lang = pathSegments[1];

  // If lang is missing or invalid, default to English **without redirecting**
  const locale = validLanguages.includes(lang) ? lang : 'en';

  return <App locale={locale} />;
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<DetectLocaleWrapper />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

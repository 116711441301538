import { IQuizItem } from '../types/quiz';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import '../styles/Quiz.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Gtm } from '../apis/gtm';
import { reportToMakeBq } from '../utils/bq';
import { getQuizById, Quiz as QuizType } from '../data/quizzes';
import { brandConfig } from '../config/brandConfig';
import { DefaultProgressBar } from './progressBars/DefaultProgressBar';
import { InfoQuestion } from './questions/interstitial/InfoQuestion';
import { InputQuestion } from './questions/interstitial/InputQuestion';
import { StudyInfoQuestion } from './questions/interstitial/StudyInfoQuestion';
import { SummaryQuestion } from './questions/interstitial/SummaryQuestion';
import '../styles/questions/StudyInfoQuestion.scss';
import '../styles/questions/SummaryQuestion.scss';
import { useTranslation } from 'react-i18next';
import { QuestionLottie } from './parts/QuestionLottie';

// Custom InterstitialLoader component
const InterstitialLoader = ({
  item,
  onSubmit,
}: {
  item: IQuizItem;
  onSubmit: (value?: string) => void;
}) => {
  const { t } = useTranslation();
  const duration = item.duration || 5000;
  
  useEffect(() => {
    // Auto-progress after duration
    const timer = setTimeout(() => {
      onSubmit(item.key);
    }, duration);
    
    return () => clearTimeout(timer);
  }, [duration, onSubmit, item.key]);
  
  return (
    <div className="interstitial-loader-container">
      <p className="interstitial-loader-text">
        {item.loaderTexts && item.loaderTexts.length > 0 ? t(item.loaderTexts[0]) : t('Analyzing your answers...')}
      </p>
      
      {item.lottie ? (
        <div className="interstitial-loader-lottie">
          <QuestionLottie item={item} />
        </div>
      ) : (
        <div className="interstitial-loader-image">
          <img 
            src={item.image || '/images/lulutox-loader-before-after.png'} 
            alt="Before and After Results" 
          />
        </div>
      )}
    </div>
  );
};

export const QuizLulutoxInterstitial = ({
  questionsId = 'lu-interstitial-gw',
  nextUrl = 'https://getwellaray.com/products/product-sub/',
}: {
  questionsId?: string;
  nextUrl?: string;
}) => {
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const [idx, setIdx] = useState(0);
  const [answers, setAnswer] = useState({});
  const [progress, setProgress] = useState(0);
  const direction = 'forward';
  const [quiz, setQuiz] = useState<QuizType | null>(null);
  const quizStartTracked = useRef(false);
  const params = new URLSearchParams(location.search);
  
  // Check if we're on the German route and set language accordingly
  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/de/')) {
      i18n.changeLanguage('de');
    } else {
      i18n.changeLanguage('en');
    }
  }, [location, i18n]);

  const getBrandFromHostname = () => {
    const hostname = window.location.hostname;

    // Check each brand's domains
    for (const [brand, config] of Object.entries(brandConfig)) {
      if (config.domains?.some((domain) => hostname.endsWith(domain))) {
        return brand;
      }
    }

    // Fallback to the old logic for backward compatibility
    const parts = hostname.split('.');
    if (parts.length >= 2) {
      const potentialBrand = parts[parts.length - 2];
      if (brandConfig[potentialBrand]) {
        return potentialBrand;
      }
    }
    return 'lulutox'; // Default to lulutox for this interstitial
  };

  const brandParam = params.get('brand') || getBrandFromHostname();
  const brandLogo = brandConfig[brandParam]?.logo;

  useEffect(() => {
    const selectedQuiz = getQuizById(questionsId);
    if (selectedQuiz) {
      setQuiz(selectedQuiz);
    } else {
      console.error(`Quiz with id ${questionsId} not found`);
    }
  }, [questionsId]);

  useEffect(() => {
    if (!quizStartTracked.current) {
      Gtm.trackQuizStart(brandParam);

      reportToMakeBq({
        dataset: 'bigquery',
        table: 'quiz_events',
        data: {
          brand: brandParam,
          url: window.location.href,
          event: 'quiz_start',
          auid: params.get('utm_auid'),
        },
      });
      quizStartTracked.current = true;
    }
  }, [params, brandParam]);

  useEffect(() => {
    if (quiz) {
      setProgress(((idx + 1) / quiz.questions.length) * 100);
    }
  }, [idx, quiz]);

  const question = quiz?.questions[idx] as IQuizItem;

  const onAnswerSubmit = async (value: string) => {
    
    const newAnswers = {
      ...answers,
      [question.key]: {
        value,
        title: value,
      },
    };

    setAnswer(newAnswers);

    // Complete mapping of question keys to English titles
    const questionMap = {
      // Weight goal question
      'weight_goal': 'What is your main goal?',
      
      // Gender question
      'gender': 'What is your gender?',
      
      // Age question
      'age': 'What is your age?',
      
      // Testimonial info
      'testimonial': 'Over 20,000 satisfied customers speak for themselves!',
      
      // Input questions
      'height': 'How tall are you?',
      'weight': 'What is your weight?',
      'target_weight': 'What is your target weight?',
      
      // Study info
      'diet_comparison': 'Sustainable results are achieved with Lulutox - not with restrictive diets.',
      
      // Energy level
      'energy_level': 'How would you describe your energy level in everyday life?',
      
      // Physical activity
      'physical_activity': 'How regularly are you physically active?',
      
      // Weight loss challenge
      'weight_loss_challenge': 'What is your biggest challenge when losing weight?',
      
      // Results loader
      'results_loader': 'Creating your personalized weight loss plan...',
      
      // Summary
      'summary': 'Your personalized weight loss plan is ready!'
    };
    
    // Get English translation for the question title
    let englishQuestion = question.title;
    
    // Use mapping first, if available
    if (questionMap[question.key]) {
      englishQuestion = questionMap[question.key];
    } else {
      // Fallback to translation function
      const getTranslation = (key: string) => {
        const translation = i18n.getFixedT('en')(key);
        return translation !== key ? translation : null;
      };
      
      if (question.type === 'interstitial-input') {
        // Input questions
        const translation = getTranslation(`interstitial.input.${question.key}`);
        if (translation) englishQuestion = translation;
      } else if (question.type === 'single') {
        // Single select questions
        const translation = getTranslation(`interstitial.${question.key}.question`);
        if (translation) englishQuestion = translation;
      } else if (question.type === 'loader') {
        // Loader question
        const translation = getTranslation('interstitial.results_loader.title');
        if (translation) englishQuestion = translation;
      }
    }
    
    const event = {
      brand: brandParam,
      step: idx,
      key: question.key,
      url: window.location.href,
      auid: params.get('utm_auid'),
      question: englishQuestion, // Use English translation
      answer: value,
      answer_key: value,
      questionType: question.type,
      quizId: questionsId,
    };

    Gtm.trackQuizAnswer(event);
    reportToMakeBq({
      dataset: 'bigquery',
      table: 'quiz_events',
      data: {
        ...event,
        event: 'quiz_answer',
      },
    });

    // Check if this is the last question (summary)
    if (idx === quiz.questions.length - 1) {
      // Only do external redirect if we're truly at the end
      await onFinish(newAnswers);
    } 
    // Special handling for results_loader to continue to summary instead of external redirect
    else if (question.key === 'results_loader') {
      // Always continue to the next question (summary) after loader
      setIdx(idx + 1);
    } 
    else {
      // Normal flow for other questions
      setIdx(idx + 1);
    }
  };

  const onFinish = async (
    answers: Record<string, { value: string | string[]; title: string }>
  ) => {
    Gtm.trackQuizFinish(brandParam, '');

    // Complete mapping of question keys to English titles
    const questionMap = {
      'weight_goal': 'What is your main goal?',
      'gender': 'What is your gender?',
      'age': 'What is your age?',
      'testimonial': 'Over 20,000 satisfied customers speak for themselves!',
      'height': 'How tall are you?',
      'weight': 'What is your weight?',
      'target_weight': 'What is your target weight?',
      'diet_comparison': 'Sustainable results are achieved with Lulutox - not with restrictive diets.',
      'energy_level': 'How would you describe your energy level in everyday life?',
      'physical_activity': 'How regularly are you physically active?',
      'weight_loss_challenge': 'What is your biggest challenge when losing weight?',
      'results_loader': 'Creating your personalized weight loss plan...',
      'summary': 'Your personalized weight loss plan is ready!'
    };
    
    // Get English translation for the final question title
    let englishQuestion = question.title;
    
    // Use mapping first
    if (questionMap[question.key]) {
      englishQuestion = questionMap[question.key];
    }

    await reportToMakeBq({
      dataset: 'bigquery',
      table: 'quiz_events',
      data: {
        step: idx,
        questionType: question.type,
        question: englishQuestion, // Use English translation
        key: question.key,
        brand: brandParam,
        url: window.location.href,
        event: 'quiz_finish',
        auid: params.get('utm_auid'),
        allAnswers: answers,
        quizId: questionsId,
      },
    });

    handleRedirect();
  };

  const handleRedirect = () => {
    // Regular external redirect
    const defaultNextUrl = brandConfig[brandParam]?.defaultNextUrl;

    const constructUrlWithParams = (baseUrl: string) => {
      const url = new URL(baseUrl);
      const currentParams = new URLSearchParams(location.search);
      currentParams.forEach((value, key) => {
        url.searchParams.append(key, value);
      });
      return url.toString();
    };

    let targetUrl: string;

    if (!nextUrl || nextUrl === '/') {
      targetUrl = constructUrlWithParams(defaultNextUrl);
    } else {
      targetUrl = constructUrlWithParams(nextUrl);
    }

    setTimeout(() => (window.location.href = targetUrl), 1000);
  };

  if (!quiz) {
    return <div>Loading quiz...</div>;
  }

  return (
    <div className="interstitial-container">
      {/* Logo Header */}
      <div className="interstitial-header">
        <img src={brandLogo} alt="LuluTox" className="interstitial-logo" />
      </div>
      
      {/* Progress Bar */}
      <DefaultProgressBar progress={progress} idx={idx + 1} />
      
      {/* Main Content */}
      <div className="interstitial-content">
        <div className="transition-group-container">
          <TransitionGroup className="react-transition-group">
            <CSSTransition
              key={idx}
              timeout={300}
              classNames={`question-${direction}`}
            >
              <div>
                {question.type === 'gender' ? (
                  <div className="interstitial-gender-container">
                    <div className="interstitial-gender-text">
                      <h1 className="interstitial-title">{question.title}</h1>
                      {question.subtitle && (
                        <p className="interstitial-subtitle">{question.subtitle}</p>
                      )}
                    </div>
                    
                    <div className="interstitial-gender-options">
                      <div className="interstitial-gender-option">
                        <div 
                          className="interstitial-gender-image-container"
                          onClick={() => onAnswerSubmit('male')}
                        >
                          <img 
                            src="/images/gener_men.png" 
                            alt="Male portrait" 
                            className="interstitial-gender-image"
                          />
                        </div>
                        <p className="interstitial-gender-label">
                          {question.options?.find(o => o.key === 'male')?.title || 'Männlich'}
                        </p>
                      </div>
                      
                      <div className="interstitial-gender-option">
                        <div 
                          className="interstitial-gender-image-container"
                          onClick={() => onAnswerSubmit('female')}
                        >
                          <img 
                            src="/images/gender_women.png" 
                            alt="Female portrait" 
                            className="interstitial-gender-image"
                          />
                        </div>
                        <p className="interstitial-gender-label">
                          {question.options?.find(o => o.key === 'female')?.title || 'Weiblich'}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : question.type === 'interstitial-info' ? (
                  <InfoQuestion
                    item={question}
                    onSubmit={() => onAnswerSubmit(question.key)}
                  />
                ) : question.type === 'interstitial-input' ? (
                  <InputQuestion
                    item={question}
                    onSubmit={(value) => onAnswerSubmit(value)}
                  />
                ) : question.type === 'interstitial-study-info' ? (
                  <StudyInfoQuestion
                    item={question}
                    onSubmit={() => onAnswerSubmit(question.key)}
                  />
                ) : question.type === 'loader' ? (
                  <InterstitialLoader
                    item={question}
                    onSubmit={(value) => onAnswerSubmit(value || question.key)}
                  />
                ) : question.type === 'summary' ? (
                  <SummaryQuestion
                    item={question}
                    onSubmit={() => onAnswerSubmit(question.key)}
                    answers={answers}
                    brandParam={brandParam}
                  />
                ) : (
                  <div className="interstitial-single-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', margin: '0 auto' }}>
                    <h1 className="interstitial-title">{question.title}</h1>
                    
                    {/* Options */}
                    <div className="interstitial-options" style={{ width: '100%' }}>
                      {question.options?.map((option) => (
                        <button
                          key={option.key}
                          className="interstitial-option-button"
                          onClick={() => onAnswerSubmit(option.key)}
                        >
                          {option.title}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

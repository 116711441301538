import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// ✅ Import JSON files directly from `src/locales/`
import translationsEn from './locales/en.json';
import translationsEs from './locales/es.json';
import translationsDe from './locales/de.json';

i18n.use(initReactI18next).init({
  lng: 'en', // Default language, with route-specific language handling
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
  resources: {
    en: { translation: translationsEn },
    es: { translation: translationsEs },
    de: { translation: translationsDe },
  },
});

export default i18n;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IQuizItem } from '../../../types/quiz';

type InputQuestionProps = {
  item: IQuizItem;
  onSubmit: (value: string) => void;
};

export const InputQuestion: React.FC<InputQuestionProps> = ({ item, onSubmit }) => {
  const { t, i18n } = useTranslation();
  const locale = i18n
  const [inputValue, setInputValue] = useState('');
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [isTouched, setIsTouched] = useState(false);

  const inputType = item.inputConfig?.inputType || 'number';
  const validation = item.inputConfig?.validation || {};
  
  // Get the correct field label based on inputType or a custom field from item
  const getFieldLabel = () => {
    // Always prefer the title from the question definition if available
    // This ensures consistency between the title and label with proper translations
    if (item.title) {
      return item.title;
    } else if (item.inputConfig?.fieldType === 'height') {
      return t('interstitial.input.height');
    } else if (item.inputConfig?.fieldType === 'weight') {
      return t('interstitial.input.weight');
    } else if (item.inputConfig?.fieldType === 'target_weight') {
      return t('interstitial.input.target_weight');
    }
    return '';
  };

  // Get the unit based on the field type
  const getUnit = () => {
    if (item.inputConfig?.fieldType === 'height') {
      return 'cm';
    } else if (item.inputConfig?.fieldType === 'weight' || item.inputConfig?.fieldType === 'target_weight') {
      return 'kg';
    }
    return '';
  };

  const validateInput = (value: string): boolean => {
    if (validation.required && !value.trim()) {
      setErrorKey(t('interstitial.input.required_error'));
      return false;
    }

    if (inputType === 'number') {
      const numValue = Number(value);
      
      if (isNaN(numValue)) {
        if (item.inputConfig?.fieldType === 'height') {
          setErrorKey('interstitial.input.height_error');
        } else if (item.inputConfig?.fieldType === 'weight') {
          setErrorKey('interstitial.input.weight_error');
        } else if (item.inputConfig?.fieldType === 'target_weight') {
          setErrorKey('interstitial.input.target_weight_error');
        } else {
          setErrorKey('interstitial.input.required_error');
        }
        return false;
      }
      
      // Special validation for target weight to ensure it's realistic and lower than current weight
      if (item.inputConfig?.fieldType === 'target_weight' && validation.compareWithPrevious) {
        // Get the current weight from answers stored in context or localStorage
        const currentWeight = localStorage.getItem('current_weight');
        if (currentWeight) {
          const weightValue = Number(currentWeight);
          
          // Check if target weight is higher than current weight
          if (numValue >= weightValue) {
            setErrorKey('interstitial.input.target_weight_higher_error');
            return false;
          }
          
          const diff = Math.abs(weightValue - numValue);
          if (diff > 50) { // The difference should not exceed 50 kg
            setErrorKey('interstitial.input.target_weight_realistic_error');
            return false;
          }
        }
      }
      
      // For height and weight fields, use the specific error messages if min/max validation fails
      if (validation.min !== undefined && numValue < validation.min) {
        if (item.inputConfig?.fieldType === 'height') {
          setErrorKey('interstitial.input.height_error');
        } else if (item.inputConfig?.fieldType === 'weight' || item.inputConfig?.fieldType === 'target_weight') {
          setErrorKey('interstitial.input.weight_error');
        } else {
          setErrorKey('interstitial.input.min_max_error');
        }
        return false;
      }
      
      if (validation.max !== undefined && numValue > validation.max) {
        if (item.inputConfig?.fieldType === 'height') {
          setErrorKey('interstitial.input.height_error');
        } else if (item.inputConfig?.fieldType === 'weight' || item.inputConfig?.fieldType === 'target_weight') {
          setErrorKey('interstitial.input.weight_error');
        } else {
          setErrorKey('interstitial.input.min_max_error');
        }
        return false;
      }
    }

    setErrorKey(null);
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    
    if (isTouched) {
      validateInput(value);
    }
  };

  const handleBlur = () => {
    setIsTouched(true);
    validateInput(inputValue);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (validateInput(inputValue)) {
      // Store the value in localStorage if it's the current weight
      // This will be used for comparing with target weight later
      if (item.inputConfig?.fieldType === 'weight') {
        localStorage.setItem('current_weight', inputValue);
      }
      
      onSubmit(inputValue);
    }
  };

  // Get the error message based on the errorKey
  const getErrorMessage = () => {
    if (!errorKey) return null;
    
    // Handle validation errors with parameters
    if (errorKey === 'interstitial.input.min_max_error') {
      return t(errorKey, { min: validation.min, max: validation.max });
    }
    
    return t(errorKey);
  };

  const errorMessage = getErrorMessage();

  // Get the appropriate subtitle based on field type
  const getSubtitle = () => {
    if (item.inputConfig?.fieldType === 'height') {
      return t('interstitial.input.height_subtitle');
    } else if (item.inputConfig?.fieldType === 'weight') {
      return t('interstitial.input.weight_subtitle');
    } else if (item.inputConfig?.fieldType === 'target_weight') {
      return t('interstitial.input.target_weight_subtitle');
    }
    return '';
  };

  return (
    <div className="interstitial-input-container">
      <h2 className="interstitial-input-title">{item.title}</h2>
      
      <p className="interstitial-input-subtitle">{getSubtitle()}</p>
      
      <form onSubmit={handleSubmit} className="interstitial-input-form" noValidate>
        <div className="interstitial-input-group">
          {/* <label className="interstitial-input-label">{getFieldLabel()}</label> */}
          <div className={`interstitial-input-field ${errorKey ? 'has-error' : ''}`}>
            <div className="interstitial-input-wrapper">
              <input
                type={inputType}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                className="interstitial-input"
                // Removed min/max attributes to disable native browser validation
                // as we're implementing our own custom validation
              />
              
            </div>
            {errorKey && (
              <p className="interstitial-input-error">
                {errorKey === 'interstitial.input.required_error' && i18n.language === 'de' 
                  ? "Dieses Feld ist erforderlich" 
                  : errorMessage}
              </p>
            )}
          </div>
        </div>
        
        <button 
          type="submit" 
          className="interstitial-input-button"
          disabled={!!errorKey && isTouched}
        >
          {item.cta || t('interstitial.input.button')}
        </button>
      </form>
    </div>
  );
};

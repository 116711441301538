import React from 'react';
import { IQuizItem } from '../../../types/quiz';

type InfoQuestionProps = {
  item: IQuizItem;
  onSubmit: () => void;
};

export const InfoQuestion: React.FC<InfoQuestionProps> = ({ item, onSubmit }) => {
  return (
    <div className="interstitial-info-container">
      <h2 className="interstitial-info-title">{item.title}</h2>
      
      {item.image && (
        <div className="interstitial-info-image-container">
          <img 
            src={item.image} 
            alt="Testimonial" 
            className="interstitial-info-image"
          />
        </div>
      )}
      
      {item.subtitle && (
        <p className="interstitial-info-description">{item.subtitle}</p>
      )}
      
      {item.subtitleHtml && (
        <p className="interstitial-info-description" dangerouslySetInnerHTML={{ __html: item.subtitleHtml }}></p>
      )}
      
      <button className="interstitial-info-button" onClick={onSubmit}>
        {item.cta || "Weiter >"}
      </button>
    </div>
  );
};

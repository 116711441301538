import { Quiz } from '../../data/quizzes';
import { IQuizItem } from '../../types/quiz';
import { Trans } from 'react-i18next';

type Props = {
  handleBack: () => void;
  brandLogo: string;
  idx: number;
  quiz: Quiz;
};

export const BannerHeader = ({ handleBack, brandLogo, idx, quiz }: Props) => {
  return (
    <div className="quiz-header-banner-container">
      {idx == 0 && (
        <div className="quiz-header-banner">
          <div className="banner-header-container">
            <p>
              <Trans i18nKey="common.greeting" components={{ 1: <b /> }} />
            </p>
          </div>
        </div>
      )}
      <img className="logo logo-banner" src={brandLogo} alt="Logo" />
    </div>
  );
};

import { useEffect, useState } from 'react';
import { IQuizItem } from '../../types/quiz';
import { OptionButtons } from '../parts/OptionButtons';
import { QuestionImage } from '../parts/QuestionImage';
import { QuestionText } from '../parts/QuestionText';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

type Props = {
  item: IQuizItem;
  onSubmit: (value: string) => void;
  lastQuestion?: boolean;
  answers: Record<string, string | string[]>;
  duration?: number; // Duration in milliseconds (e.g., 5000 for 5 seconds)
};

export const Loader = ({
  item,
  onSubmit,
  lastQuestion,
  answers,
  duration = 5000,
}: Props) => {
  const [progress, setProgress] = useState(0);
  const texts = item.loaderTexts;
  const [textIndex, setTextIndex] = useState(0);
  const [text, setText] = useState(texts[textIndex]);
  const [imageIndex, setImageIndex] = useState(0);
  const { t } = useTranslation();
  const locale = i18n.language;
  const images =
    locale === 'es' && item.image_es
      ? [item.image_es]
      : item.images || [item.image];

  useEffect(() => {
    if (textIndex < texts.length - 1 || imageIndex < images.length - 1) {
      const interval = setInterval(() => {
        setTextIndex((prevIndex) => prevIndex + 1);
        if (texts[textIndex]) {
          setText(texts[textIndex]);
        }
        setImageIndex((prevIndex) =>
          prevIndex < images.length - 1 ? prevIndex + 1 : prevIndex
        );
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [textIndex, texts.length, images.length]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (progress < 100) {
      const increment = 100 / (duration / 50);
      interval = setInterval(() => {
        setProgress((prev) => Math.min(prev + increment, 100));
      }, 50);
    } else if (progress >= 100) {
      setText(t('common.found_match'));
      clearInterval(interval);
      onSubmit(null);
    }

    return () => clearInterval(interval);
  }, [progress, duration, onSubmit]);

  return (
    <div className="loader-container">
      <h2 dangerouslySetInnerHTML={{ __html: t(item.titleHtml) }}></h2>
      <p className="analyzing-text">{t(text)}</p>
      <div className="loader-progress-bar">
        <div
          className="loader-progress"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className="before-after-single">
        <img
          key={imageIndex}
          className="fade-image"
          src={images[imageIndex]}
          alt="Before and After"
          style={
            item.imageWidth
              ? { width: item.imageWidth, maxWidth: '100%' }
              : undefined
          }
        />
      </div>
    </div>
  );
};
